import React from 'react';
import { AppBar, Button, Container, Typography, Box, Toolbar, CssBaseline } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import '../styles/LandingPage.css';

const Portal = () => {
    const navigate = useNavigate();

    const handleMemberLogin = () => {
        navigate('/member-login');
    };

    const handleSellerLogin = () => {
        navigate('/seller-login');
    };
    const handleMediaLogin = () => {
        navigate('/media-login');
    };
    const handleBuyerLogin = () => {
        navigate('/buyer-login');
    };

    return (
        <>
            <CssBaseline />
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
               <Header/>

                <Container component="main" maxWidth="md" sx={{ mt: 8, mb: 2 }} className="landing-container">
                    <Typography variant="h3" component="h1" gutterBottom className="landing-title">
                        Kerala Travel Mart 2024
                    </Typography>
                    <Typography variant="h4" component="h2" gutterBottom className="landing-subtitle">
                        Pass Generation Portal
                    </Typography>
                    <Typography variant="h5" gutterBottom className="landing-subtitle">
                        Please select your login type
                    </Typography>
                    <Box className="landing-buttons" sx={{ mt: 4 }}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleMemberLogin} 
                            className="landing-button"
                            sx={{ mr: { xs: 0, sm: 2 }, mb: { xs: 2, sm: 0 } }}
                        >
                            Member Pass Generation
                        </Button>
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            onClick={handleSellerLogin} 
                            className="landing-button"
                            sx={{ mr: { xs: 0, sm: 2 }, mb: { xs: 2, sm: 0 } }}
                        >
                            Seller Pass Generation
                        </Button>
                        <Button 
                            variant="contained" 
                            color="warning" 
                            onClick={handleBuyerLogin} 
                            className="landing-button"
                            sx={{ mr: { xs: 0, sm: 2 }, mb: { xs: 2, sm: 0 } }}
                        >
                            Buyer Pass Generation
                        </Button>
                        <Button 
                            variant="contained" 
                            color="success" 
                            onClick={handleMediaLogin} 
                            className="landing-button"
                            sx={{ mr: { xs: 0, sm: 2 }, mb: { xs: 2, sm: 0 } }}
                        >
                            Media Pass Generation
                        </Button>
                    </Box>
                </Container>

                <Footer/>
            </Box>
        </>
    );
};

export default Portal;