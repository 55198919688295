import React, { useState, useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Container,
  Paper,
  Typography,
  Box,
  Grid,
  Input,
  IconButton,
  Select,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const steps = ['Passport & Visa', 'Travel', 'Bank Details', 'Additional Bank Info'];

const countries = [
  "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria",
  "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan",
  "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon",
  "Canada", "Cape Verde", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica",
  "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador",
  "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia", "Fiji", "Finland", "France", "Gabon",
  "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana",
  "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel",
  "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South",
  "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
  "Luxembourg", "Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania",
  "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar",
  "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman",
  "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar",
  "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent", "Samoa", "San Marino", "Sao Tome", "Saudi Arabia",
  "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
  "Spain", "Sri Lanka", "Sudan", "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan",
  "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda",
  "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
  "Yemen", "Zambia", "Zimbabwe"
];

function ReimbursementForm() {
  const [activeStep, setActiveStep] = useState(0);
  const [isSimpleForm, setIsSimpleForm] = useState(false);
  const [isEligible, setIsEligible] = useState(null);
  const [refundStatus, setRefundStatus] = useState(null);
  const [formData, setFormData] = useState({
    id: '',
    domestic: {
      account_holder_name: '',
      account_holder_address: '',
      account_number: '',
      bank_name: '',
      branch_name: '',
      ifsc_code: '',
    },
    passport: {
      country: '',
      passport_number: '',
      passport_place_of_issue: '',
      passport_issue_date: null,
      passport_expiry_date: null,
      documents: [],
    },
    visa: {
      visa_number: '',
      visa_place_of_issue: '',
      visa_issue_date: null,
      visa_expiry_date: null,
    },
    travel: {
      ticket_invoices: [],
      boarding_passes: [
        {
          from_city: '',
          to_city: '',
          documents: [],
        }
      ]
    },
    bank: {
      account_holder_name: '',
      name_of_bank: '',
      bank_address: '',
      account_number: '',
      branch_code: '',
      swift_code: '',
      iban_number: '',
      bic_code: '',
      sort_code: '',
      routing_number: '',
      transit_number: '',
      bsb_number: '',
      remarks: '',
      documents: [],
    }
  });

  // Add new state for validation
  const [errors, setErrors] = useState({});

  // Add new state for tracking submission success
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [hasBoardingPass, setHasBoardingPass] = useState(null);
  const [boardingPasses, setBoardingPasses] = useState([
    { from_city: '', to_city: '', photo: null }
  ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    
    // Set the ID in formData
    setFormData(prev => ({
      ...prev,
      id: id || ''
    }));

    const checkEligibility = async () => {
      if (!id) return;

      const numericId = id.match(/\d+/)?.[0];
      if (!numericId) return;

      const idNum = parseInt(numericId);
      setIsSimpleForm(idNum < 4000);

      try {
        if (idNum < 6000) {
          const response = await axios.get(`https://api.tessst.com/buyers/refund-status/?id=${id}`);
          setIsEligible(response.data.eligible_for_refund);
          setRefundStatus(response.data.refund_status);
          if (idNum >= 4000) {
            setHasBoardingPass(response.data.has_boarding_pass);
          }
        } else if (idNum >= 6000 && idNum <= 8000) {
          setIsEligible(false);
        } else if (idNum > 8000) {
          const response = await axios.get(`https://api.tessst.com/buyers/media-refund-status/?id=${id}`);
          setIsEligible(response.data.eligible_for_refund);
          setRefundStatus(response.data.refund_status);
        }
      } catch (error) {
        console.error('Error checking eligibility:', error);
        setIsEligible(false);
      }
    };

    checkEligibility();
  }, []);

  // Add validation functions
  const validatePassportVisa = () => {
    const newErrors = {};
    const passport = formData.passport;
    
    if (!passport.country) newErrors.country = 'Country is required';
    if (!passport.passport_number) newErrors.passport_number = 'Passport number is required';
    if (!passport.passport_place_of_issue) newErrors.passport_place_of_issue = 'Place of issue is required';
    if (!passport.passport_issue_date) newErrors.passport_issue_date = 'Issue date is required';
    if (!passport.passport_expiry_date) newErrors.passport_expiry_date = 'Expiry date is required';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateTravel = () => {
    const newErrors = {};
    const { boarding_passes, ticket_invoices } = formData.travel;

    // Validate boarding passes
    boarding_passes.forEach((pass, index) => {
      if (!pass.from_city) newErrors[`from_city_${index}`] = 'From city is required';
      if (!pass.to_city) newErrors[`to_city_${index}`] = 'To city is required';
      if (!pass.documents || pass.documents.length === 0) {
        newErrors[`documents_${index}`] = 'At least one boarding pass document is required';
      }
    });

    // Validate ticket invoices
    if (!ticket_invoices || ticket_invoices.length === 0) {
      newErrors.ticket_invoices = 'At least one ticket invoice is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateBank = () => {
    const newErrors = {};
    const bank = formData.bank;

    // Mandatory fields
    if (!bank.account_holder_name) newErrors.account_holder_name = 'Account holder name is required';
    if (!bank.name_of_bank) newErrors.name_of_bank = 'Bank name is required';
    if (!bank.bank_address) newErrors.bank_address = 'Bank address is required';
    if (!bank.account_number) newErrors.account_number = 'Account number is required';
    if (!bank.swift_code) newErrors.swift_code = 'Swift code is required';
    
    // branch_code is optional, so no validation needed

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    let isValid = false;

    switch (activeStep) {
      case 0:
        isValid = validatePassportVisa();
        break;
      case 1:
        isValid = validateTravel();
        break;
      case 2:
        isValid = validateBank();
        break;
      default:
        isValid = true;
    }

    if (isValid) {
      setActiveStep((prev) => prev + 1);
      setErrors({});  // Clear errors when moving to next step
    }
  };

  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleChange = (section, field, value) => {
    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value,
      },
    }));
  };

  const handleNestedChange = (section, subsection, field, value) => {
    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [subsection]: {
          ...prev[section][subsection],
          [field]: value,
        },
      },
    }));
  };

  const handleFileChange = (section, field, files) => {
    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: [...prev[section][field], ...Array.from(files)],
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      if (isSimpleForm) {
        const response = await axios.post('https://api.tessst.com/buyers/domestic-refund/', {
          id: formData.id,
          ...formData.domestic
        });
        console.log('Success:', response.data);
        setIsSubmitted(true);
      } else {
        const formDataToSend = new FormData();
        
        // Append JSON data
        formDataToSend.append('id', formData.id);
        formDataToSend.append('passport', JSON.stringify(formData.passport));
        formDataToSend.append('visa', JSON.stringify(formData.visa));
        formDataToSend.append('travel', JSON.stringify(formData.travel));
        formDataToSend.append('bank', JSON.stringify(formData.bank));

        // Append files
        formData.passport.documents.forEach((file) => {
          formDataToSend.append('passport.documents', file);
        });

        formData.travel.ticket_invoices.forEach((file) => {
          formDataToSend.append('travel.ticket_invoices', file);
        });

        formData.travel.boarding_passes.forEach((pass, index) => {
          pass.documents.forEach((file) => {
            formDataToSend.append(`travel.boarding_passes.${index}.documents`, file);
          });
        });

        formData.bank.documents.forEach((file) => {
          formDataToSend.append('bank.documents', file);
        });

        const response = await axios.post('https://api.tessst.com/buyers/reimbursement/', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log('Success:', response.data);
        setIsSubmitted(true);
      }
      handleNext();
    } catch (error) {
      console.error('Error:', error);
      
      // Handle error messages
      let errorMessage = 'An error occurred while submitting the form.';
      
      if (error.response) {
        // The server responded with an error
        if (error.response.data.error) {
          errorMessage = error.response.data.error;
        } else if (typeof error.response.data === 'object') {
          // Handle validation errors from the server
          const errors = [];
          Object.entries(error.response.data).forEach(([key, value]) => {
            errors.push(`${key}: ${Array.isArray(value) ? value.join(', ') : value}`);
          });
          errorMessage = errors.join('\n');
        }
      }
      
      // Set the error in state
      setErrors(prev => ({
        ...prev,
        submit: errorMessage
      }));
    }
  };

  const handleFileDelete = (section, field, fileIndex) => {
    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: prev[section][field].filter((_, index) => index !== fileIndex)
      }
    }));
  };

  const handleAddBoardingPass = () => {
    setFormData(prev => ({
      ...prev,
      travel: {
        ...prev.travel,
        boarding_passes: [
          ...prev.travel.boarding_passes,
          {
            from_city: '',
            to_city: '',
            documents: [],
          }
        ]
      }
    }));
  };

  const handleBoardingPassSubmit = async () => {
    try {
      const formData = new FormData();
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('id');
    
    // Set the ID in formData
      const numericId = id.match(/\d+/)?.[0];
      if (!numericId) return;

      const idNum = parseInt(numericId);

      formData.append('id', id);
      formData.append('boarding_passes', JSON.stringify(
        boardingPasses.map(({ from_city, to_city }) => ({ from_city, to_city }))
      ));

      // Append photos with unique keys
      boardingPasses.forEach((pass, index) => {
        if (pass.photo) {
          formData.append(`photo_${pass.from_city}_${pass.to_city}`, pass.photo);
        }
      });

      await axios.post('https://api.tessst.com/buyers/boarding-pass/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      // Refresh the page to update status
      window.location.reload();
    } catch (error) {
      console.error('Error submitting boarding passes:', error);
      setErrors(prev => ({
        ...prev,
        submit: error.response?.data?.error || 'Failed to submit boarding passes'
      }));
    }
  };

  const renderBoardingPassForm = () => (
    <Paper sx={commonStyles.paper}>
      <Typography variant="h6" gutterBottom>
        Upload Boarding Passes
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        Please upload your boarding passes to complete the refund process.
      </Typography>

      {boardingPasses.map((pass, index) => (
        <Box key={index} sx={{ mb: 3, p: 2, bgcolor: 'grey.50', borderRadius: 1 }}>
          <Typography variant="subtitle1" gutterBottom>
            Boarding Pass #{index + 1}
            {boardingPasses.length > 1 && (
              <IconButton
                size="small"
                onClick={() => setBoardingPasses(prev => prev.filter((_, i) => i !== index))}
                sx={{ ml: 1 }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="From City"
                value={pass.from_city}
                onChange={(e) => {
                  const newPasses = [...boardingPasses];
                  newPasses[index].from_city = e.target.value;
                  setBoardingPasses(newPasses);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="To City"
                value={pass.to_city}
                onChange={(e) => {
                  const newPasses = [...boardingPasses];
                  newPasses[index].to_city = e.target.value;
                  setBoardingPasses(newPasses);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FileUploadSection
                title="Boarding Pass Document"
                onChange={(e) => {
                  const newPasses = [...boardingPasses];
                  newPasses[index].photo = e.target.files[0];
                  setBoardingPasses(newPasses);
                }}
                files={pass.photo ? [pass.photo] : []}
                onDelete={() => {
                  const newPasses = [...boardingPasses];
                  newPasses[index].photo = null;
                  setBoardingPasses(newPasses);
                }}
                required
              />
            </Grid>
          </Grid>
        </Box>
      ))}

      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setBoardingPasses(prev => [...prev, { from_city: '', to_city: '', photo: null }])}
        sx={{ mb: 3 }}
      >
        Add Another Boarding Pass
      </Button>

      {errors.submit && <ErrorAlert message={errors.submit} />}

      <Box sx={commonStyles.buttonContainer}>
        <Button
          variant="contained"
          onClick={handleBoardingPassSubmit}
          disabled={!boardingPasses.every(pass => 
            pass.from_city && pass.to_city && pass.photo
          )}
        >
          Submit Boarding Passes
        </Button>
      </Box>
    </Paper>
  );

  const commonStyles = {
    paper: {
      p: { xs: 2, sm: 3 },
      mt: 2,
      borderRadius: 2,
      boxShadow: 3,
    },
    stepper: {
      mb: 4,
      display: { xs: 'none', sm: 'flex' }, // Hide stepper on mobile
    },
    mobileStep: {
      display: { xs: 'block', sm: 'none' },
      mb: 3,
      textAlign: 'center',
    },
    sectionTitle: {
      mb: 3,
      color: 'primary.main',
      fontWeight: 'medium',
    },
    fileUpload: {
      mt: 2,
      p: 2,
      border: '2px dashed',
      borderColor: 'primary.light',
      borderRadius: 1,
      textAlign: 'center',
      cursor: 'pointer',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      mt: 4,
      pt: 2,
      borderTop: 1,
      borderColor: 'divider',
    },
  };

  const FileUploadPreview = ({ files, onDelete }) => (
    <Box sx={{ mt: 2 }}>
      {files.map((file, index) => (
        <Paper
          key={index}
          sx={{
            p: 1.5,
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            bgcolor: 'grey.50',
            px: { xs: 1, sm: 2 },
            wordBreak: 'break-word',
          }}
        >
          <Typography 
            variant="body2" 
            sx={{ 
              flex: 1,
              mr: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {file.name}
          </Typography>
          <IconButton 
            size="small" 
            onClick={() => onDelete(index)} 
            color="error"
            sx={{ 
              flexShrink: 0,
              padding: { xs: 1, sm: 0.5 } 
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Paper>
      ))}
    </Box>
  );

  const FileUploadSection = ({ title, onChange, files, onDelete, error, helperText, required }) => (
    <Box>
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        {title} {required && <span style={{ color: 'error.main' }}>*</span>}
      </Typography>
      <Box
        component="label"
        sx={{
          ...commonStyles.fileUpload,
          minHeight: { xs: 100, sm: 80 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          '&:active': {
            bgcolor: 'action.hover',
          },
          p: { xs: 3, sm: 2 },
          borderColor: error ? 'error.main' : 'primary.light',
        }}
      >
        <Input
          type="file"
          multiple
          sx={{ display: 'none' }}
          onChange={onChange}
          accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
        />
        <Typography 
          variant="body2" 
          color={error ? 'error' : 'text.secondary'}
          align="center"
        >
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            Tap to select files
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            Click or drag files to upload
          </Box>
        </Typography>
        <Typography 
          variant="caption" 
          color={error ? 'error' : 'text.secondary'}
          align="center"
        >
          Supported formats: PDF, DOC, DOCX, JPG, PNG
        </Typography>
      </Box>
      <FileUploadPreview files={files} onDelete={onDelete} />
      {error && (
        <Typography variant="caption" color="error" sx={{ mt: 1, display: 'block' }}>
          {helperText}
        </Typography>
      )}
    </Box>
  );

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>Passport Details</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      fullWidth
                      options={countries}
                      value={formData.passport.country}
                      onChange={(_, newValue) => handleChange('passport', 'country', newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          required
                          error={!!errors.country}
                          helperText={errors.country}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="Passport Number"
                      value={formData.passport.passport_number}
                      onChange={(e) => handleChange('passport', 'passport_number', e.target.value)}
                      error={!!errors.passport_number}
                      helperText={errors.passport_number}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="Place of Issue"
                      value={formData.passport.passport_place_of_issue}
                      onChange={(e) => handleChange('passport', 'passport_place_of_issue', e.target.value)}
                      error={!!errors.passport_place_of_issue}
                      helperText={errors.passport_place_of_issue}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Issue Date"
                      value={formData.passport.passport_issue_date}
                      onChange={(date) => handleChange('passport', 'passport_issue_date', date)}
                      slotProps={{
                        textField: {
                          required: true,
                          error: !!errors.passport_issue_date,
                          helperText: errors.passport_issue_date
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Expiry Date"
                      value={formData.passport.passport_expiry_date}
                      onChange={(date) => handleChange('passport', 'passport_expiry_date', date)}
                      slotProps={{
                        textField: {
                          required: true,
                          error: !!errors.passport_expiry_date,
                          helperText: errors.passport_expiry_date
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FileUploadSection
                      title="Passport Documents"
                      onChange={(e) => handleFileChange('passport', 'documents', e.target.files)}
                      files={formData.passport.documents}
                      onDelete={(index) => handleFileDelete('passport', 'documents', index)}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Visa Details (Optional)</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Visa Number"
                      value={formData.visa.visa_number}
                      onChange={(e) => handleChange('visa', 'visa_number', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Place of Issue"
                      value={formData.visa.visa_place_of_issue}
                      onChange={(e) => handleChange('visa', 'visa_place_of_issue', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Issue Date"
                      value={formData.visa.visa_issue_date}
                      onChange={(date) => handleChange('visa', 'visa_issue_date', date)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Expiry Date"
                      value={formData.visa.visa_expiry_date}
                      onChange={(date) => handleChange('visa', 'visa_expiry_date', date)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LocalizationProvider>
        );

      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Travel Details</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Boarding Passes</Typography>
              {formData.travel.boarding_passes.map((pass, index) => (
                <Paper key={index} sx={{ p: 2, mb: 2, bgcolor: 'grey.50' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Boarding Pass #{index + 1}
                        {formData.travel.boarding_passes.length > 1 && (
                          <IconButton 
                            size="small" 
                            onClick={() => {
                              setFormData(prev => ({
                                ...prev,
                                travel: {
                                  ...prev.travel,
                                  boarding_passes: prev.travel.boarding_passes.filter((_, i) => i !== index)
                                }
                              }));
                            }}
                            sx={{ ml: 1 }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        required
                        label="From City"
                        value={pass.from_city}
                        onChange={(e) => {
                          const newPasses = [...formData.travel.boarding_passes];
                          newPasses[index].from_city = e.target.value;
                          setFormData(prev => ({
                            ...prev,
                            travel: { ...prev.travel, boarding_passes: newPasses }
                          }));
                        }}
                        error={!!errors[`from_city_${index}`]}
                        helperText={errors[`from_city_${index}`]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        required
                        label="To City"
                        value={pass.to_city}
                        onChange={(e) => {
                          const newPasses = [...formData.travel.boarding_passes];
                          newPasses[index].to_city = e.target.value;
                          setFormData(prev => ({
                            ...prev,
                            travel: { ...prev.travel, boarding_passes: newPasses }
                          }));
                        }}
                        error={!!errors[`to_city_${index}`]}
                        helperText={errors[`to_city_${index}`]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FileUploadSection
                        title="Boarding Pass Documents"
                        onChange={(e) => {
                          const newPasses = [...formData.travel.boarding_passes];
                          newPasses[index].documents = [...pass.documents, ...Array.from(e.target.files)];
                          setFormData(prev => ({
                            ...prev,
                            travel: { ...prev.travel, boarding_passes: newPasses }
                          }));
                        }}
                        files={pass.documents}
                        onDelete={(fileIndex) => {
                          const newPasses = [...formData.travel.boarding_passes];
                          newPasses[index].documents = pass.documents.filter((_, i) => i !== fileIndex);
                          setFormData(prev => ({
                            ...prev,
                            travel: { ...prev.travel, boarding_passes: newPasses }
                          }));
                        }}
                        error={!!errors[`documents_${index}`]}
                        helperText={errors[`documents_${index}`]}
                        required
                      />
                    </Grid>
                  </Grid>
                </Paper>
              ))}
              
              <Button
                variant="outlined"
                onClick={handleAddBoardingPass}
                startIcon={<AddIcon />}
                sx={{ mt: 2 }}
              >
                Add Another Boarding Pass
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>Ticket Invoices</Typography>
              <FileUploadSection
                title="Upload Ticket Invoices"
                onChange={(e) => handleFileChange('travel', 'ticket_invoices', e.target.files)}
                files={formData.travel.ticket_invoices}
                onDelete={(index) => handleFileDelete('travel', 'ticket_invoices', index)}
                error={!!errors.ticket_invoices}
                helperText={errors.ticket_invoices}
                required
              />
            </Grid>
          </Grid>
        );

      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Bank Details</Typography>
            </Grid>
            {[
              { field: 'account_holder_name', label: 'Account Holder Name', required: true },
              { field: 'name_of_bank', label: 'Bank Name', required: true },
              { field: 'bank_address', label: 'Bank Address', required: true },
              { field: 'account_number', label: 'Account Number', required: true },
              { field: 'branch_code', label: 'Branch Code', required: false },
              { field: 'swift_code', label: 'Swift Code', required: true },
            ].map(({ field, label, required }) => (
              <Grid item xs={12} md={6} key={field}>
                <TextField
                  fullWidth
                  label={label}
                  value={formData.bank[field]}
                  onChange={(e) => handleChange('bank', field, e.target.value)}
                  error={!!errors[field]}
                  helperText={errors[field]}
                  required={required}
                />
              </Grid>
            ))}
          </Grid>
        );

      case 3:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Additional Bank Information</Typography>
            </Grid>
            {[
              { field: 'iban_number', label: 'IBAN Number' },
              { field: 'bic_code', label: 'BIC Code' },
              { field: 'sort_code', label: 'Sort Code' },
              { field: 'routing_number', label: 'Routing Number' },
              { field: 'transit_number', label: 'Transit Number' },
              { field: 'bsb_number', label: 'BSB Number' },
              { field: 'remarks', label: 'Remarks', multiline: true, rows: 4 },
            ].map(({ field, label, multiline, rows }) => (
              <Grid item xs={12} md={multiline ? 12 : 6} key={field}>
                <TextField
                  fullWidth
                  label={label}
                  value={formData.bank[field]}
                  onChange={(e) => handleChange('bank', field, e.target.value)}
                  multiline={multiline}
                  rows={rows}
                  error={!!errors[field]}
                  helperText={errors[field]}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <FileUploadSection
                title="Bank Documents"
                onChange={(e) => handleFileChange('bank', 'documents', e.target.files)}
                files={formData.bank.documents}
                onDelete={(index) => handleFileDelete('bank', 'documents', index)}
              />
            </Grid>
          </Grid>
        );

      default:
        return null;
    }
  };

  const renderSimpleForm = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>Refund Details</Typography>
      </Grid>
      {[
        { field: 'account_holder_name', label: 'Account Holder Name' },
        { field: 'account_holder_address', label: 'Account Holder Address', multiline: true, rows: 3 },
        { field: 'account_number', label: 'Account Number' },
        { field: 'bank_name', label: 'Bank Name' },
        { field: 'branch_name', label: 'Bank Address and Branch Name' },
        { field: 'ifsc_code', label: 'IFSC Code' },
      ].map(({ field, label, multiline, rows }) => (
        <Grid item xs={12} key={field}>
          <TextField
            fullWidth
            required
            label={label}
            value={formData.domestic[field]}
            onChange={(e) => handleChange('domestic', field, e.target.value)}
            multiline={multiline}
            rows={rows}
          />
        </Grid>
      ))}
    </Grid>
  );

  const renderStatusMessage = () => (
    <Paper sx={commonStyles.paper}>
      <Box sx={{ textAlign: 'center', py: 4 }}>
        {!isEligible ? (
          <>
            <Typography variant="h5" color="error" gutterBottom>
              Not Eligible
            </Typography>
            <Typography variant="body1" color="text.secondary">
              You are not eligible for refund/reimbursement.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h5" color="primary" gutterBottom>
              Refund Status
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Your refund request status: {refundStatus}
            </Typography>
          </>
        )}
      </Box>
    </Paper>
  );

  // Add success screen render function
  const renderSuccessScreen = () => (
    <Paper sx={commonStyles.paper}>
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="h5" color="primary" gutterBottom>
          Submission Complete!
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Thank you for your submission. We'll process your refund request shortly.
        </Typography>
      </Box>
    </Paper>
  );

  // Add this component to display submission errors
  const ErrorAlert = ({ message }) => (
    <Paper 
      sx={{ 
        p: 2, 
        mb: 2, 
        bgcolor: 'error.light',
        color: 'error.contrastText'
      }}
    >
      <Typography variant="body2" component="pre" sx={{ whiteSpace: 'pre-wrap' }}>
        {message}
      </Typography>
    </Paper>
  );

  return (
    <Container maxWidth="md" sx={{ pb: 8 }}>
      {isEligible === null ? (
        // Loading state
        <Paper sx={commonStyles.paper}>
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="h6" color="text.secondary">
              Checking eligibility...
            </Typography>
          </Box>
        </Paper>
      ) : refundStatus?.toLowerCase() === 'submitted' && 
      !isSimpleForm && 
      !hasBoardingPass ? (
        renderBoardingPassForm()
      ) : !isEligible || (refundStatus && refundStatus.toLowerCase() !== 'pending') ? (
        // Show status message if not eligible or refund status is not pending
        renderStatusMessage()
      ) : (
        // Show form only if eligible and status is pending
        <>
          {errors.submit && <ErrorAlert message={errors.submit} />}
          
          <Paper sx={commonStyles.paper}>
            {isSimpleForm ? (
              <>
                {isSubmitted ? (
                  renderSuccessScreen()
                ) : (
                  <>
                    {renderSimpleForm()}
                    <Box sx={commonStyles.buttonContainer}>
                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ minWidth: 100 }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <>
                <Stepper activeStep={activeStep} sx={commonStyles.stepper}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <Box sx={commonStyles.mobileStep}>
                  <Typography variant="h6" color="primary">
                    Step {activeStep + 1}: {steps[activeStep]}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {activeStep + 1} of {steps.length}
                  </Typography>
                </Box>

                {activeStep === steps.length ? (
                  <Box sx={{ textAlign: 'center', py: 4 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                      Submission Complete!
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Thank you for your submission. We'll process your reimbursement request shortly.
                    </Typography>
                  </Box>
                ) : (
                  <>
                    {renderStepContent(activeStep)}
                  </>
                )}
              </>
            )}
          </Paper>
          {!isSimpleForm && activeStep !== steps.length && (
            <Paper 
              sx={{ 
                position: 'fixed', 
                bottom: 0, 
                left: 0, 
                right: 0, 
                p: 2,
                bgcolor: 'background.paper',
                borderTop: 1,
                borderColor: 'divider',
                zIndex: 1000
              }}
            >
              <Container maxWidth="md">
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    variant="outlined"
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                    sx={{ minWidth: 100 }}
                  >
                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                  </Button>
                </Box>
              </Container>
            </Paper>
          )}
        </>
      )}
    </Container>
  );
}

export default ReimbursementForm; 