import React, { useState } from "react";
import CryptoJS from "crypto-js"; // Import crypto-js

const Checkout2 = () => {
  const [formData, setFormData] = useState({
    buyerEmail: "arun@sprdh.com",
    buyerFirstName: "Arun",
    buyerLastName: "KL",
    orderid: "16",
    amount: "4500.00",
    UID: "16",
    mercid: "320677",
    kittype: "server_side_sdk", 
    currency: 356, 
    isocurrency: "INR",
    txnsubtype: 2,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var date = new Date()
    const privateKey = "wHkTGDq9BxRqgaKT";
    const username = "KPvYTBQ65X"; 
    const password = "4NC2tNMs"; 
    const alldata = `${formData.buyerEmail}${formData.buyerFirstName}${formData.buyerLastName}${formData.amount}${formData.orderid}${formData.UID}`+ date.toISOString().split('T')[0]+"";
    const key = CryptoJS.SHA256(`${username}~:~${password}`).toString(); // Use crypto-js for hashing
    const checksum = CryptoJS.SHA256(key+"@"+alldata).toString();

    console.log(alldata);

    const postData = {
      ...formData,
      privatekey: CryptoJS.SHA256(`${privateKey}@${username}:|:${password}`).toString(),
      checksum: checksum,
    };


    // Create a form element and submit it for redirection
    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://payments.airpay.co.in/pay/index.php";

    // Append form fields
    for (const key in postData) {
      const input = document.createElement("input");
      input.type = "text";
      input.name = key;
      input.value = postData[key];
      form.appendChild(input);
    }

    document.body.appendChild(form);
    // form.submit();
  };

  return (
    <div>
      <h2>Airpay Payment Form</h2>
        <button type="submit" onClick={handleSubmit}>Proceed to Payment</button>
    </div>
  );
};

export default Checkout2;
