import React, { useEffect, useMemo, useState } from 'react';
import { Drawer, Box, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import api from '../utils/api';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const Cart = ({ open, onClose,fetchProfile,fetchMainCart }) => {
  const [items, setItems] = useState([]); // State to hold cart items
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // Fetch cart items when the cart is opened
  useEffect(() => {
    if (open) {
      setLoading(true);
      api.get('sellers/cart/')
        .then((response) => {
          setItems(response.data); // Assuming the API returns an array of items
          setLoading(false);
        })
        .catch((error) => {
          console.error('Failed to fetch cart items:', error);
          setLoading(false);
        });
    }
  }, [open]);
  useEffect(() => {
    fetchCart();
  }, []);

  const fetchCart = async () => {
    setLoading(true);
      api.get('sellers/cart/')
        .then((response) => {
          setItems(response.data); // Assuming the API returns an array of items
          setLoading(false);
        })
        .catch((error) => {
          console.error('Failed to fetch cart items:', error);
          setLoading(false);
        });
  };
  const removeFromCart = (id, passType) => {
    api.delete(`sellers/cart/${passType}/${id}/`) // Send delete request to the API with passType and id
      .then(() => {
        setItems(items.filter(item => item.id !== id || item.passType !== passType)); // Filter based on both id and passType
        fetchCart();
        fetchProfile();
        fetchMainCart();
      })
      .catch((error) => {
        console.error('Failed to remove item from cart:', error);
      });
  };

  const handleCheckout = () => {
    // Implement checkout logic here
    console.log('Proceeding to checkout with items:', items);
    navigate('/checkout');

  };

  const totalAmount = useMemo(() => {
    return items.reduce((total, item) => {
      return total + (item.code === 'ASP' ? 4500 : (item.code === 'SIP' || item.code === 'SNP' ? 4000 : 0));
    }, 0);
  }, [items]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          width: 300,
          p: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography variant="h6" gutterBottom>
          <ShoppingCartIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
          Shopping Cart
        </Typography>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : items.length === 0 ? (
          <Typography>Your cart is empty.</Typography>
        ) : (
          <>
            <List sx={{ flexGrow: 1, overflow: 'auto' }}>
              {items.map((item) => (
                <ListItem key={item.id}>
                  <ListItemText
                    primary={item.name}
                    secondary={`${item.type} - ₹ ${item.code === 'ASP' ? 4500 : (item.code === 'SIP' || item.code === 'SNP' ? 4000 : 0)}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => removeFromCart(item.id, item.code)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Typography variant="h6" align="right" sx={{ mt: 2 }}>
              Total: ₹ {totalAmount}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleCheckout}
              sx={{ mt: 2 }}
            >
              Proceed to Checkout (₹ {totalAmount})
            </Button>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default Cart;
