import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Button, Alert, CircularProgress, TextField, Paper, Grid } from '@mui/material';
import api from '../utils/api';

const MartExitScanner = () => {
  const [inputValue, setInputValue] = useState('');
  const [exitData, setExitData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleScan = async () => {
    if (inputValue) {
      setLoading(true);
      setError(null);

      try {
        const response = await api.post('buyers/mart-exit/', { qr_code: inputValue });
        setExitData(response.data);
        setInputValue(''); // Clear input after successful scan
      } catch (err) {
        setError(err.response?.data?.error || 'An error occurred while processing the exit');
      } finally {
        setLoading(false);
        inputRef.current.focus(); // Refocus input after scan
      }
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Conference Exit Scanner
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Enter QR Code"
              variant="outlined"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleScan();
                }
              }}
              inputRef={inputRef}
              fullWidth
              autoFocus
            />
          </Box>
          <Button variant="contained" onClick={handleScan} disabled={!inputValue || loading}>
            Process Exit
          </Button>
          {loading && <CircularProgress sx={{ ml: 2 }} />}
          {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        </Grid>
        <Grid item xs={12} md={6}>
          {exitData && (
            <Paper elevation={3} sx={{ p: 2, bgcolor: 'success.light', height: '100%' }}>
              <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                Exit Processed
              </Typography>
              <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 1 }}>
                <Typography>{exitData.message}</Typography>
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MartExitScanner;
