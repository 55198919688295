import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress
} from '@mui/material';
import Avatar from 'react-avatar-edit';
import axios from 'axios';

const categories = [
  'Committee',
  'Tourism',
  'Gov Officials',
  'Sponsors',
  'Press',
  'Inaugural Event Team',
  'Event Management Team'
];

const defaultPassTypes = ['Regular'];

const eventManagementPassTypes = [
  'Stark Communications',
  'Executive Events',
  'Mobile App Support',
  'WIFI Support',
  'Software Support',
  'Infrastructure Team',
  'Tour Guide',
  'Transport Team',
  'Food Court Crew',
  'House Keeping',
  'Security',
  'Invitee Pass',
  'Crew Members',
  'Transport Crew'
];

const VolunteerPass = ({onPassCreated }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    category: '',
    pass_type: '',
    photo: null
  });
  const [preview, setPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [passTypes, setPassTypes] = useState(defaultPassTypes);

  useEffect(() => {
    if (formData.category === 'Event Management Team') {
      setPassTypes(eventManagementPassTypes);
      setFormData(prev => ({ ...prev, pass_type: '' })); // Reset pass type when category changes
    } else {
      setPassTypes(defaultPassTypes);
      setFormData(prev => ({ ...prev, pass_type: '' })); // Reset pass type when category changes
    }
  }, [formData.category]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const onClose = () => {
    setPreview(null);
  };

  const onCrop = (preview) => {
    setPreview(preview);
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 2000000) {
      alert("File is too big!");
      elem.target.value = "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await api.post('/buyers/entry-passes/', {
        ...formData,
        photo: preview
      });
      setSuccess('Pass created successfully!');
      setFormData({
        name: '',
        email: '',
        category: '',
        pass_type: '',
        photo: null
      });
      setPreview(null);
      onPassCreated();
    } catch (error) {
      setError('Failed to create pass. Please try again.');
      console.error('Error creating pass:', error);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, margin: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        Create Volunteer Pass
      </Typography>
      <TextField
        fullWidth
        margin="normal"
        name="name"
        label="Name"
        value={formData.name}
        onChange={handleInputChange}
        required
      />
      <Typography gutterBottom>
       photo
      </Typography>
      <Box sx={{ my: 2 }}>
        <Avatar
          width={200}
          height={200}
          onCrop={onCrop}
          onClose={onClose}
          onBeforeFileLoad={onBeforeFileLoad}
          src={null}
        />
      </Box>
      {preview && (
        <Box sx={{ my: 2 }}>
          <Typography variant="subtitle1">Preview:</Typography>
          <img src={preview} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
        </Box>
      )}
      <Button 
        type="submit" 
        variant="contained" 
        color="primary" 
        fullWidth 
        sx={{ mt: 2 }}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : 'Create Pass'}
      </Button>
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      {success && (
        <Typography color="success" sx={{ mt: 2 }}>
          {success}
        </Typography>
      )}
    </Box>
  );
};

export default VolunteerPass;