import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Paper, Avatar, Button, CircularProgress, TextField, Grid, Badge, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import Header from './Header';
import Footer from './Footer';
import api from '../utils/api';
import AvatarEditor from 'react-avatar-edit';
import EditIcon from '@mui/icons-material/Edit';
import ErrorModal from './ErrorModal';

const ProfileBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'linear-gradient(145deg, #f0f4ff 0%, #e0e8ff 100%)',
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
}));

const CartButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(4),
  right: theme.spacing(4),
  zIndex: 1000,
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(20),
  marginBottom: theme.spacing(2),
  border: `4px solid ${theme.palette.background.paper}`,
  boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
}));

const ProfileField = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.5),
  background: 'rgba(255,255,255,0.6)',
  borderRadius: '8px',
  width: '100%',
  textAlign: 'center',
}));

const UploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const DownloadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
}));

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [passPhoto, setPassPhoto] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);


  const handleClose = () => setError(null);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const response = await api.get('/buyers/media-profile/');
      setProfile(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to load profile. Please try again later.');
      setLoading(false);
    }
  };

  const handlePhotoUpload = async (croppedImage) => {
    if (!croppedImage) return;

    const formData = new FormData();
    formData.append('photo', croppedImage);

    setUploading(true);
    try {
      await api.put('/buyers/media-update/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await fetchProfile(); // Refresh profile data
      setIsEditing(false); // Hide the AvatarEditor component
    } catch (error) {
      if (error.response && error.response.data && error.response.data.photo) {
        setError(error.response.data.photo);
      } else {
        setError('Failed to upload photo. Please try again.');
      }
    } finally {
      setUploading(false);
    }
  };





  const handleDownloadPass = async () => {
    setDownloading(true);
    try {
      const response = await api.get('/buyers/media-pass/', {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'member_pass.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setError('Failed to download pass. Please try again.');
    } finally {
      setDownloading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Container component="main" maxWidth="md" sx={{ mt: 8, mb: 2 }}>
        {error &&
          <ErrorModal error={error} handleClose={handleClose} />
        }
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <ProfileBox>
              <ProfileAvatar
                src={isEditing ? passPhoto : profile.photo ? profile.photo : null}
                alt={profile.name}
              >

              </ProfileAvatar>
              {isEditing ? (
                <>
                  <AvatarEditor
                    src={passPhoto}
                    width={250}
                    height={250}
                    border={50}
                    color={[255, 255, 255, 0.6]}
                    scale={1.2}
                    rotate={0}
                    exportAsSquare={true}
                    exportQuality={0.1}
                    exportSize={200}
                    onCrop={(croppedImage) => setCroppedImage(croppedImage)}
                  />
                </>
              ) : !profile.photo && <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
                onClick={() => setIsEditing(true)}
                sx={{ mt: 2 }}

              >
                {uploading ? 'Uploading...' : 'Upload Photo to Download Pass'}
              </Button>
              }
              {croppedImage && !profile.photo &&
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CloudUploadIcon />}
                  onClick={() => handlePhotoUpload(croppedImage)}
                  disabled={!croppedImage}
                  sx={{ mt: 2 }}
                >
                  {uploading ? 'Uploading...' : 'Upload Photo'}
                </Button>
              }
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#1e3a8a' }}>
                {profile.name}
              </Typography>
              <ProfileField><strong>Email:</strong> {profile.email}</ProfileField>
              <ProfileField><strong>Phone:</strong> {profile.phone}</ProfileField>
              <ProfileField><strong>Registration Number:</strong> {profile.registration}</ProfileField>
              <ProfileField><strong>Organization:</strong> {profile.organization_name}</ProfileField>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {profile.photo && (
                  <DownloadButton
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadPass}
                    disabled={downloading}
                  >
                    {downloading ? 'Downloading...' : 'Download Pass'}
                  </DownloadButton>
                )}
              </Box>
            </ProfileBox>
          </Grid>
        </Grid>
        <br/>
        <Typography gutterBottom sx={{ color: '#1e3a8a', fontWeight: 700 }}>
          For any technical assistance, please write to arun@sprdh.com
        </Typography>
      </Container>
      <Footer />
    </Box>
  );
};

export default Profile;