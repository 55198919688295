import React, { useState, useEffect } from 'react';
import { 
  Paper, 
  Typography, 
  Grid, 
  Box, 
  CircularProgress,
  Divider,
  Link,
  IconButton,
  Button,
  Menu,
  MenuItem
} from '@mui/material';
import { useParams } from 'react-router-dom';
import api from '../utils/api';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import LinkIcon from '@mui/icons-material/Link';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const DetailItem = ({ label, value }) => (
  <Box mb={1}>
    <Typography variant="subtitle2" color="textSecondary">{label}</Typography>
    <Typography>{value || 'Not provided'}</Typography>
  </Box>
);

const DocumentViewer = ({ title, documents }) => {
  if (!documents || documents.length === 0) return null;

  const isFileType = (url, type) => {
    return url.toLowerCase().endsWith(type);
  };

  const downloadFile = (url, filename) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      });
  };

  const renderDocument = (url, index) => {
    const fullUrl = url.startsWith('http') ? url : `https://api.tessst.com${url}`;

    if (isFileType(url, 'pdf')) {
      return (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mb: 1,
          }}
        >
          <PictureAsPdfIcon color="error" />
          <Link
            href={fullUrl}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              textDecoration: 'none',
              color: 'primary.main',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {`${title} ${index + 1}.pdf`}
          </Link>
        </Box>
      );
    }

    return (
      <Box
        key={index}
        sx={{
          position: 'relative',
          width: 150,
          height: 150,
          border: '1px solid #e0e0e0',
          borderRadius: 1,
          overflow: 'hidden',
          m: 1,
          display: 'inline-block',
        }}
      >
        <img
          src={fullUrl}
          alt={`${title} ${index + 1}`}
          loading="lazy"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            cursor: 'pointer',
          }}
          onClick={() => window.open(fullUrl, '_blank')}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 0.5,
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: 'white',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {`${title} ${index + 1}`}
          </Typography>
          <IconButton
            size="small"
            sx={{ color: 'white' }}
            onClick={() => downloadFile(fullUrl, `${title}_${index + 1}.${url.split('.').pop()}`)}
          >
            <DownloadIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    );
  };

  return (
    <Box mt={2}>
      <Typography variant="subtitle1" gutterBottom>
        {title}
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {documents.map((url, index) => renderDocument(url, index))}
      </Box>
    </Box>
  );
};

const BuyerDetails = ({ buyerId }) => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentStatus, setCurrentStatus] = useState('processing');

  const handleStatusClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = async (newStatus) => {
    try {
      await api.post('update-refund-status/', {
        id: buyerId,
        status: newStatus
      });
      setCurrentStatus(newStatus);
      handleStatusClose();
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const endpoint = parseInt(buyerId) < 3000 
          ? 'buyers/refund/'
          : 'buyers/reimbursement/';
        
        const response = await api.get(endpoint, {
          params: { id: buyerId }
        });
        
        const data = parseInt(buyerId) < 3000 ? {
          bank: {
            accountHolder: response.data.account_holder_name,
            accountHolderAddress: response.data.account_holder_address,
            accountNumber: response.data.account_number,
            bankName: response.data.bank_name,
            branchName: response.data.branch_name,
            ifscCode: response.data.ifsc_code,
          },
          personal: {},
          travel: { boardingPasses: [], ticketInvoices: [] },
          visa: {},
          passport: { documents: [] }
        } : response.data;

        setDetails(data);
      } catch (err) {
        setError('Failed to fetch buyer details');
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [buyerId]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!details) return <Typography>No details found</Typography>;

  const isDomesticRefund = parseInt(buyerId) < 3000;

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button
          variant="contained"
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleStatusClick}
        >
          Change Status
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleStatusClose}
        >
          <MenuItem onClick={() => handleStatusChange('processing')}>Processing</MenuItem>
          <MenuItem onClick={() => handleStatusChange('completed')}>Completed</MenuItem>
          <MenuItem onClick={() => handleStatusChange('resubmit')}>Resubmit</MenuItem>
        </Menu>
      </Box>

      <Grid container spacing={3}>
        {/* Only show Personal & Travel Section for international refunds */}
        {!isDomesticRefund && (
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>Personal Details</Typography>
              <DetailItem label="Country" value={details.personal.country} />
              
              <Box mt={3}>
                <Typography variant="h6" gutterBottom>Travel Information</Typography>
                {details.travel.boardingPasses && details.travel.boardingPasses.map((pass, index) => (
                  <Box key={index} mb={2}>
                    <Typography variant="subtitle2" color="textSecondary">Flight {index + 1}</Typography>
                    <DetailItem label="From City" value={pass.fromCity} />
                    <DetailItem label="To City" value={pass.toCity} />
                    {pass.document && (
                      <DocumentViewer 
                        title={`Boarding Pass ${index + 1}`}
                        documents={[pass.document]} 
                      />
                    )}
                  </Box>
                ))}
                
                <DocumentViewer 
                  title="Ticket Invoices" 
                  documents={details.travel.ticketInvoices} 
                />
              </Box>
            </Paper>
          </Grid>
        )}

        {/* Only show Visa & Passport Section for international refunds */}
        {!isDomesticRefund && (
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>Visa Details</Typography>
              <DetailItem label="Visa Number" value={details.visa.number} />
              <DetailItem label="Place of Issue" value={details.visa.placeOfIssue} />
              <DetailItem label="Issue Date" value={details.visa.issueDate} />
              <DetailItem label="Expiry Date" value={details.visa.expiryDate} />

              <Divider sx={{ my: 2 }} />

              <Typography variant="h6" gutterBottom>Passport Details</Typography>
              <DetailItem label="Passport Number" value={details.passport.number} />
              <DetailItem label="Place of Issue" value={details.passport.placeOfIssue} />
              <DetailItem label="Issue Date" value={details.passport.issueDate} />
              <DetailItem label="Expiry Date" value={details.passport.expiryDate} />
              
              <DocumentViewer 
                title="Passport Documents" 
                documents={details.passport.documents} 
              />
            </Paper>
          </Grid>
        )}

        {/* Bank Details Section */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Bank Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <DetailItem label="Account Holder" value={details.bank.accountHolder} />
                {isDomesticRefund && (
                  <DetailItem label="Account Holder Address" value={details.bank.accountHolderAddress} />
                )}
                <DetailItem label="Bank Name" value={details.bank.bankName} />
                {isDomesticRefund ? (
                  <>
                    <DetailItem label="Branch Name" value={details.bank.branchName} />
                    <DetailItem label="Account Number" value={details.bank.accountNumber} />
                    <DetailItem label="IFSC Code" value={details.bank.ifscCode} />
                  </>
                ) : (
                  <>
                    <DetailItem label="Bank Address" value={details.bank.bankAddress} />
                    <DetailItem label="Account Number" value={details.bank.accountNumber} />
                    <DetailItem label="Branch Code" value={details.bank.branchCode} />
                    <DetailItem label="Swift Code" value={details.bank.swiftCode} />
                  </>
                )}
              </Grid>
              {/* Only show additional bank fields for international refunds */}
              {!isDomesticRefund && (
                <>
                  <Grid item xs={12} md={6}>
                    <DetailItem label="IBAN Number" value={details.bank.ibanNumber} />
                    <DetailItem label="BIC Code" value={details.bank.bicCode} />
                    <DetailItem label="Sort Code" value={details.bank.sortCode} />
                    <DetailItem label="Routing Number" value={details.bank.routingNumber} />
                    <DetailItem label="Transit Number" value={details.bank.transitNumber} />
                    <DetailItem label="BSB Number" value={details.bank.bsbNumber} />
                  </Grid>
                  <Grid item xs={12}>
                    <DetailItem label="Remarks" value={details.bank.remarks} />
                    <DocumentViewer 
                      title="Bank Documents" 
                      documents={details.bank.documents} 
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BuyerDetails;
