import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button, TextField, Container, Typography, Box, Paper, CircularProgress,
  Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Checkbox, Select, MenuItem
} from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import api from '../utils/api';

const KeralaMarketSurvey = () => {
  const [formData, setFormData] = useState({
    sends_clients_to_india: '',
    sends_clients_to_kerala: '',
    reason_not_sending_to_kerala: '',
    has_exclusive_kerala_itinerary: '',
    business_to_kerala: '',
    key_factor_for_kerala: '',
    top_destinations: ['', '', '', '', ''],
    booking_time: '',
    average_client_age: '',
    client_type: '',
    average_stay_duration: '',
    popular_indian_states: ['', '', '', '', ''],
    similar_destinations: ['', '', ''],
    kerala_pricing: '',
    kerala_description: '',
    name: '',
    company_name: '',
    position: '',
    company_profile: '',
    num_employees: '0',
    city: '',
    country: '',
    buyer_id: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const buyerId = searchParams.get('buyer_id');
    if (buyerId) {
      setFormData(prevData => ({
        ...prevData,
        buyer_id: buyerId
      }));
    }
  }, [location]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleArrayChange = (index, field) => (event) => {
    const newArray = [...formData[field]];
    newArray[index] = event.target.value;
    setFormData(prevData => ({
      ...prevData,
      [field]: newArray
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');
    try {
      await api.post('buyers/kerala-market-survey/', formData);
      navigate('/thank-you');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError('An error occurred while submitting the survey. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Container component="main" maxWidth="md" sx={{ mt: 8, mb: 2 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom>
            Kerala Travel Mart 2024 Survey
          </Typography>
          <Typography variant="body1" paragraph>
            Dear friend,

            Greetings from Kerala Travel Mart 2024!

            We value your partnership and would love to hear your thoughts on how Kerala Tourism can enhance its efforts to promote and market the state as a premier travel destination. 

            As part of Kerala Tourism's ongoing commitment to growth, we are conducting this survey among trade visitors attending Kerala Travel Mart 2024. Your feedback is vital in helping us create better experiences and solutions tailored to your needs. We greatly appreciate you taking the time to share your insights.
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl component="fieldset" margin="normal" fullWidth required>
              <FormLabel component="legend">1. Do you send clients to India?</FormLabel>
              <RadioGroup
                name="sends_clients_to_india"
                value={formData.sends_clients_to_india}
                onChange={handleChange}
              >
                <FormControlLabel value="true" control={<Radio required />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" margin="normal" fullWidth required>
              <FormLabel component="legend">2. Do you send clients to Kerala?</FormLabel>
              <RadioGroup
                name="sends_clients_to_kerala"
                value={formData.sends_clients_to_kerala}
                onChange={handleChange}
              >
                <FormControlLabel value="true" control={<Radio required />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            {formData.sends_clients_to_kerala === 'false' && (
              <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend">a. If No, why? (Select only one)</FormLabel>
                <RadioGroup
                  name="reason_not_sending_to_kerala"
                  value={formData.reason_not_sending_to_kerala}
                  onChange={handleChange}
                >
                  <FormControlLabel value="Distance" control={<Radio />} label="Distance" />
                  <FormControlLabel value="Connectivity" control={<Radio />} label="Connectivity" />
                  <FormControlLabel value="Visa problems" control={<Radio />} label="Visa problems" />
                  <FormControlLabel value="Rates" control={<Radio />} label="Rates" />
                  <FormControlLabel value="Lack of information" control={<Radio />} label="Lack of information" />
                  <FormControlLabel value="Lack of business associates" control={<Radio />} label="Lack of business associates" />
                  <FormControlLabel value="Not interested in the destination" control={<Radio />} label="Not interested in the destination" />
                  <FormControlLabel value="Other" control={<Radio />} label="Other" />
                </RadioGroup>
              </FormControl>
            )}

            {formData.sends_clients_to_kerala === 'true' && (
              <>
                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">3. Do you have an exclusive Kerala tour itinerary (not linked with any other Indian destinations)?</FormLabel>
                  <RadioGroup
                    name="has_exclusive_kerala_itinerary"
                    value={formData.has_exclusive_kerala_itinerary}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">4. How is your business to Kerala?</FormLabel>
                  <RadioGroup
                    name="business_to_kerala"
                    value={formData.business_to_kerala}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="Growing" control={<Radio />} label="Growing" />
                    <FormControlLabel value="Declining" control={<Radio />} label="Declining" />
                    <FormControlLabel value="Same as before" control={<Radio />} label="Same as before" />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">5. What is the key factor for a client to select a Kerala holiday?</FormLabel>
                  <RadioGroup
                    name="key_factor_for_kerala"
                    value={formData.key_factor_for_kerala}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="India Holiday" control={<Radio />} label="India Holiday" />
                    <FormControlLabel value="Sunny beaches" control={<Radio />} label="Sunny beaches" />
                    <FormControlLabel value="Wellness" control={<Radio />} label="Wellness" />
                    <FormControlLabel value="Backwater/houseboats" control={<Radio />} label="Backwater/houseboats" />
                    <FormControlLabel value="Nature/Greenery" control={<Radio />} label="Nature/Greenery" />
                    <FormControlLabel value="Peaceful/Relaxing" control={<Radio />} label="Peaceful/Relaxing" />
                    <FormControlLabel value="Safety" control={<Radio />} label="Safety" />
                    <FormControlLabel value="Value for money" control={<Radio />} label="Value for money" />
                    <FormControlLabel value="Exotic" control={<Radio />} label="Exotic" />
                    <FormControlLabel value="Enlightening/Spiritual" control={<Radio />} label="Enlightening/Spiritual" />
                    <FormControlLabel value="Experiences" control={<Radio />} label="Experiences" />
                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">6. Which are the top 5 destinations that you showcase in your Kerala tour itinerary?</FormLabel>
                  {[0, 1, 2, 3, 4].map((index) => (
                    <TextField
                      key={index}
                      label={`Destination ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formData.top_destinations[index]}
                      onChange={handleArrayChange(index, 'top_destinations')}
                    />
                  ))}
                </FormControl>

                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">7. How early do your clients book their Kerala holiday?</FormLabel>
                  <RadioGroup
                    name="booking_time"
                    value={formData.booking_time}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="Less than 2 weeks" control={<Radio />} label="Less than 2 weeks" />
                    <FormControlLabel value="1-3 months" control={<Radio />} label="1-3 months" />
                    <FormControlLabel value="3 – 6 months" control={<Radio />} label="3 – 6 months" />
                    <FormControlLabel value="6 – 12 months" control={<Radio />} label="6 – 12 months" />
                    <FormControlLabel value="More than 12 months" control={<Radio />} label="More than 12 months" />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">8. What is the average age of your clients travelling to Kerala?</FormLabel>
                  <RadioGroup
                    name="average_client_age"
                    value={formData.average_client_age}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="Under 21 years" control={<Radio />} label="Under 21 years" />
                    <FormControlLabel value="21-30" control={<Radio />} label="21-30" />
                    <FormControlLabel value="30-45" control={<Radio />} label="30-45" />
                    <FormControlLabel value="45-60" control={<Radio />} label="45-60" />
                    <FormControlLabel value="above 60 years" control={<Radio />} label="above 60 years" />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">9. Are your clients to Kerala mostly</FormLabel>
                  <RadioGroup
                    name="client_type"
                    value={formData.client_type}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="FITs" control={<Radio />} label="FITs" />
                    <FormControlLabel value="Small groups" control={<Radio />} label="Small groups" />
                    <FormControlLabel value="Families" control={<Radio />} label="Families" />
                    <FormControlLabel value="Big Groups" control={<Radio />} label="Big Groups" />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">10. What will be average duration of stay in Kerala by your guests?</FormLabel>
                  <RadioGroup
                    name="average_stay_duration"
                    value={formData.average_stay_duration}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="< 7 days" control={<Radio />} label="< 7 days" />
                    <FormControlLabel value="7-14 days" control={<Radio />} label="7-14 days" />
                    <FormControlLabel value="14-28 days" control={<Radio />} label="14-28 days" />
                    <FormControlLabel value="28 days plus" control={<Radio />} label="28 days plus" />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">11. Please rank the 5 most popular Indian states for your clients? (Rank 1 for the most popular, 2 for next, so on....)</FormLabel>
                  {[0, 1, 2, 3, 4].map((index) => (
                    <TextField
                      key={index}
                      label={`State ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formData.popular_indian_states[index]}
                      onChange={handleArrayChange(index, 'popular_indian_states')}
                    />
                  ))}
                </FormControl>

                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">12. Name 3 international destinations that you think are similar to Kerala</FormLabel>
                  {[0, 1, 2].map((index) => (
                    <TextField
                      key={index}
                      label={`Destination ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formData.similar_destinations[index]}
                      onChange={handleArrayChange(index, 'similar_destinations')}
                    />
                  ))}
                </FormControl>

                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">13. Do you think Kerala is</FormLabel>
                  <RadioGroup
                    name="kerala_pricing"
                    value={formData.kerala_pricing}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="Expensive" control={<Radio />} label="Expensive" />
                    <FormControlLabel value="Cheap" control={<Radio />} label="Cheap" />
                    <FormControlLabel value="Good value for money" control={<Radio />} label="Good value for money" />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">14. How would you describe Kerala in one word?</FormLabel>
                  <RadioGroup
                    name="kerala_description"
                    value={formData.kerala_description}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="Safe" control={<Radio />} label="Safe" />
                    <FormControlLabel value="Rejuvenating" control={<Radio />} label="Rejuvenating" />
                    <FormControlLabel value="Peaceful" control={<Radio />} label="Peaceful" />
                    <FormControlLabel value="Ethereal" control={<Radio />} label="Ethereal" />
                    <FormControlLabel value="Heaven" control={<Radio />} label="Heaven" />
                    <FormControlLabel value="Enlightening" control={<Radio />} label="Enlightening" />
                    <FormControlLabel value="Green" control={<Radio />} label="Green" />
                    <FormControlLabel value="Enchanting" control={<Radio />} label="Enchanting" />
                    <FormControlLabel value="Relaxing" control={<Radio />} label="Relaxing" />
                    <FormControlLabel value="Beautiful" control={<Radio />} label="Beautiful" />
                    <FormControlLabel value="Home" control={<Radio />} label="Home" />
                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                  </RadioGroup>
                </FormControl>
              </>
            )}

            <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
              Company Details
            </Typography>

            <TextField
              name="name"
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.name}
              onChange={handleChange}
            />

            <TextField
              name="company_name"
              label="Company Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.company_name}
              onChange={handleChange}
            />

            <TextField
              name="position"
              label="Position"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.position}
              onChange={handleChange}
            />

            <FormControl fullWidth margin="normal">
              <FormLabel component="legend">Company Profile</FormLabel>
              <RadioGroup
                name="company_profile"
                value={formData.company_profile}
                onChange={handleChange}
              >
                <FormControlLabel value="Tour Operator / Wholesaler" control={<Radio />} label="Tour Operator / Wholesaler" />
                <FormControlLabel value="Travel Agent / Retailer" control={<Radio />} label="Travel Agent / Retailer" />
                <FormControlLabel value="Online Travel Agent" control={<Radio />} label="Online Travel Agent" />
                <FormControlLabel value="Other" control={<Radio />} label="Other" />
              </RadioGroup>
            </FormControl>

            <TextField
              name="num_employees"
              label="No. of employees"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.num_employees}
              onChange={handleChange}
              type="number"
              inputProps={{ min: 0 }}
            />

            <TextField
              name="city"
              label="City"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.city}
              onChange={handleChange}
            />

            <TextField
              name="country"
              label="Country"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.country}
              onChange={handleChange}
            />

            <Box sx={{ mt: 3, position: 'relative' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
                fullWidth
                sx={{
                  backgroundColor: '#1e3a8a',
                  '&:hover': {
                    backgroundColor: '#2c5282',
                  },
                }}
              >
                {isLoading ? 'Submitting...' : 'Submit Survey'}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </form>
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default KeralaMarketSurvey;