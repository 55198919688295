import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Paper, Avatar, Button, CircularProgress, TextField, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Tabs, Tab, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DownloadIcon from '@mui/icons-material/Download';
import Header from './Header';
import Footer from './Footer';
import Cart from './Cart';
import api from '../utils/api';
import AvatarEditor from 'react-avatar-edit';
import ErrorModal from './ErrorModal'; // Assuming the modal is in ErrorModal.js

const ProfileBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'linear-gradient(145deg, #f0f4ff 0%, #e0e8ff 100%)',
  borderRadius: '12px',
  boxShadow: '0 5px 10px rgba(0,0,0,0.1)',
}));

const CartButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 1000,
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  marginBottom: theme.spacing(2),
  border: `3px solid ${theme.palette.background.paper}`,
  boxShadow: '0 3px 8px rgba(0,0,0,0.1)',
}));

const ProfileField = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),
  background: 'rgba(255,255,255,0.6)',
  borderRadius: '6px',
  width: '100%',
  textAlign: 'center',
}));

const UploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const DownloadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));



const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    flexWrap: 'wrap',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.7rem',
    minWidth: 'auto',
    padding: '6px 8px',
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cartLength, setCartLength] = useState(0);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [passType, setPassType] = useState('seller');
  const [downloading, setDownloading] = useState(false);
  const [additionalPassName, setAdditionalPassName] = useState('');
  const [additionalPassPhoto, setAdditionalPassPhoto] = useState(null);
  const [cart, setCart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [generatingPass, setGeneratingPass] = useState(false);


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  useEffect(() => {
    fetchProfile();
    fetchCart();
  }, []);






  const fetchProfile = async () => {
    try {
      const response = await api.get('/sellers/seller-profile/');
      setProfile(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to load profile. Please try again later.');
      setLoading(false);
    }
  };

  const fetchCart = async () => {
    try {
      const response = await api.get('sellers/cart/')
        .then((response) => {
          setCartLength(response.data.length); // Assuming the API returns an array of items
        })
        .catch((error) => {
          console.error('Failed to fetch cart items:', error);
        });
    } catch (err) {
      setError('Failed to load profile. Please try again later.');
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (index) => {
    setTabValue(index);
    handleMenuClose();
  };

  const handleCreateSellerPass = async () => {
    if (!additionalPassName || !croppedImage) return;

    setGeneratingPass(true);
    setUploading(true);
    const formData = new FormData();
    formData.append('photo', croppedImage);
    formData.append('name', additionalPassName);

    try {
      const response = await api.post('sellers/create-seller-pass/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Seller pass created successfully:', response.data);
      fetchProfile(); // Refresh profile data to reflect new passes
    } catch (error) {
      if (error.response && error.response.data && error.response.data.photo) {
        setError(error.response.data.photo);
      } else {
        setError('Failed to create seller pass. Please try again.');
      }

    } finally {
      setUploading(false);
      setGeneratingPass(false);
    }
    setAdditionalPassName('');
    setAdditionalPassPhoto(null);
    setCroppedImage(null);
    setOpenModal(false);
  };

  // const handleAdditionalPassPhotoUpload = (event) => {
  //   const file = event.target.files[0];
  //   setAdditionalPassPhoto(file);
  // };

  const handleAdditionalPassPhotoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setAdditionalPassPhoto(reader.result);
    });
    reader.readAsDataURL(file);
  };


  const getRemainingPasses = (profile, cart, passType) => {
    let remainingPasses = 0;

    if (passType === 'additional') {
      remainingPasses = profile.slots - profile.additional_seller_pass_count;
      const additionalPassesInCart = cart.filter(item => item.type === 'ASP').length;
      remainingPasses -= additionalPassesInCart;
    } else if (passType === 'inaugural') {
      remainingPasses = 1 - profile.inaugural_pass_count;
      const inauguralPassesInCart = cart.filter(item => item.type === 'SIP').length;
      remainingPasses -= inauguralPassesInCart;
    } else if (passType === 'networking') {
      remainingPasses = 1 - profile.networking_pass_count;
      const networkingPassesInCart = cart.filter(item => item.type === 'SNP').length;
      remainingPasses -= networkingPassesInCart;
    }

    return remainingPasses;
  };


  const handleAddToCart = async () => {
    setGeneratingPass(true)
    if (additionalPassName && additionalPassPhoto) {
      let newItem = null;
      let remainingPasses = 0;



      if (passType === 'additional') {
        var url = "sellers/create-additional-seller-pass/"
        remainingPasses = getRemainingPasses(profile, cart, 'additional');
        if (remainingPasses > 0) {

        } else {
          // Show an error message or disable the button if the user has no remaining additional passes
          console.log('No remaining additional passes');
          return;
        }
      } else if (passType === 'inaugural') {
        var url = "sellers/seller-inaugural-pass/"
        remainingPasses = getRemainingPasses(profile, cart, 'inaugural');
        if (remainingPasses > 0) {

        } else {
          // Show an error message or disable the button if the user has no remaining inaugural passes
          console.log('No remaining inaugural passes');
          return;
        }
      } else if (passType === 'networking') {
        var url = "sellers/seller-networking-pass/"
        remainingPasses = getRemainingPasses(profile, cart, 'networking');
        if (remainingPasses > 0) {

        } else {
          // Show an error message or disable the button if the user has no remaining networking passes
          console.log('No remaining networking passes');
          return;
        }
      }



      try {
        const formData = new FormData();
        formData.append('photo', croppedImage);
        formData.append('name', additionalPassName);

        const response = await api.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        // Check if the API request was successful
        if (response.status === 200 || response.status === 201) {
          setError('');
          setIsCartOpen(true); // Open the cart if the API request is successful
          fetchCart();
          fetchProfile();
        } else {
          setError('Failed to generate pass. Please try again.');
        }
        setError('');
      } catch (error) {
        if (error.response && error.response.data && error.response.data.photo) {
          setError(error.response.data.photo);
        } else {
          setError('Failed to generate pass. Please try again.');
        }
      } finally {
        setAdditionalPassName('');
        setAdditionalPassPhoto(null);
        setOpenModal(false);
      }
    }
    setGeneratingPass(false)
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const handleDownloadPass = async () => {
    setDownloading(true);
    try {
      const response = await api.get('/sellers/member-pass/', {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'member_pass.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setError('Failed to download pass. Please try again.');
    } finally {
      setDownloading(false);
    }
  };

  const urlMap = {
    'seller': "/sellers/download-seller-pass/",
    'additional': "/sellers/download-additional-pass/",
    'inaugural': "sellers/download-seller-inaugural-pass/",
    'networking': "sellers/download-networking-pass/"
  };

  const handleDownloadSellerPass = async (uuid, pass) => {

    var api_url = urlMap[pass] || null;

    setDownloading(true);
    try {
      const response = await api.get(api_url + uuid, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'seller_pass.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setError('Failed to download pass. Please try again.');
    } finally {
      setDownloading(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setAdditionalPassName('');
    setAdditionalPassPhoto(null);
  };

  const handleClose = () => setError(null);

  const handlePassModal = (type) => {
    setOpenModal(true);
    setPassType(type)
  };

  if (loading) {
    return <CircularProgress />;
  }

  // if (error) {
  //   return <Typography color="error">{error}</Typography>;
  // }

  const remainingPasses = profile.slots - profile.seller_pass_count;
  const remainingAdditionalPasses = profile.slots - profile.additional_seller_pass_count;
  const remainingNetworkPasses = 1 - profile.networking_pass_count;
  const remainingInauguralPasses = profile.slots > 1 ? 0 : 1 - profile.inaugural_pass_count;



  const tabContent = [
    { label: "Seller Passes" },
    { label: "Additional Seller Passes" },
    { label: "Inaugural Pass" },
    { label: "Networking Dinner Pass" },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Container component="main" maxWidth="lg" sx={{ mt: 4, mb: 2 }}>
        {error &&
          <ErrorModal error={error} handleClose={handleClose} />
        }
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <ProfileBox>
              <ProfileAvatar src={profile.logo_b64 ? `data:image/png;base64,${profile.logo_b64}` : null} alt={profile.name} />
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#1e3a8a' }}>
                {profile.organization_name}
              </Typography>
              <ProfileField><strong>Organization:</strong> {profile.organization_name}</ProfileField>
              <ProfileField><strong>Seller Passes:</strong> {profile.slots} eligible, {remainingPasses} remaining</ProfileField>

              <ProfileField><strong>Additional Seller Passes:</strong> {profile.slots} eligible {remainingAdditionalPasses} remaining</ProfileField>

              <ProfileField><strong>Inaugural Pass:</strong> {profile.slots < 2 ? '1 eligible' : '0 eligible'}, {remainingInauguralPasses} remaining</ProfileField>

              <ProfileField><strong>2nd Day Networking Dinner Pass:</strong> 1 eligible, {remainingNetworkPasses} remaining</ProfileField>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                {profile.photo && (
                  <DownloadButton
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadPass}
                    disabled={downloading}
                  >
                    {downloading ? 'Downloading...' : 'Download Pass'}
                  </DownloadButton>
                )}
              </Box>
            </ProfileBox>
          </Grid>
          <Grid item xs={12} md={8}>
            <StyledTabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="pass types"
              variant={isMobile ? "scrollable" : "standard"}
              scrollButtons="auto"
            >
              {tabContent.map((tab, index) => (
                <StyledTab key={index} label={tab.label} />
              ))}
              {isMobile && (
                <StyledTab
                  icon={<MoreHorizIcon />}
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleMoreClick}
                />
              )}
            </StyledTabs>
            <TabPanel value={tabValue} index={0}>
              <ProfileBox>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#1e3a8a' }}>
                  Seller Passes
                </Typography>
                {profile && profile.seller_passes && profile.seller_passes.length > 0 ? (
                  profile.seller_passes.map((pass, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <ProfileBox>
                        <ProfileAvatar src={pass.photo_base64 ? pass.photo_base64 : null} alt={pass.name} />
                        <ProfileField><strong>Name:</strong> {pass.name}</ProfileField>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                          {pass.photo_base64 && (
                            <DownloadButton
                              variant="contained"
                              startIcon={<DownloadIcon />}
                              onClick={() => handleDownloadSellerPass(pass.uuid, "seller")}
                              disabled={downloading}
                            >
                              {downloading ? 'Downloading...' : 'Download Pass'}
                            </DownloadButton>
                          )}
                        </Box>
                      </ProfileBox>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    No seller passes available.
                  </Typography>
                )}
                {remainingPasses > 0 && (
                  <UploadButton
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                    onClick={() => handlePassModal("seller")}
                    sx={{ mt: 2 }}
                  >
                    Generate Seller Pass
                  </UploadButton>
                )}
              </ProfileBox>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <ProfileBox>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#1e3a8a' }}>
                  Additional Seller Passes
                </Typography>
                {profile && profile.additional_seller_passes && profile.additional_seller_passes.length > 0 ? (
                  profile.additional_seller_passes.map((pass, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <ProfileBox>
                        <ProfileAvatar src={pass.photo_base64 ? `${pass.photo_base64}` : null} alt={pass.name} />
                        <ProfileField><strong>Name:</strong> {pass.name}</ProfileField>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                          {pass.is_active && (
                            <DownloadButton
                              variant="contained"
                              startIcon={<DownloadIcon />}
                              onClick={() => handleDownloadSellerPass(pass.uuid, "additional")}
                              disabled={downloading}
                            >
                              {downloading ? 'Downloading...' : 'Download Pass'}
                            </DownloadButton>
                          )}
                        </Box>
                      </ProfileBox>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    No additional seller passes generated.
                  </Typography>
                )}
                {remainingAdditionalPasses > 0 && (
                  <UploadButton
                    variant="contained"
                    startIcon={<AddShoppingCartIcon />}
                    onClick={() => handlePassModal("additional")}
                    sx={{ mt: 2 }}
                  >
                    Buy Additional Seller Pass
                  </UploadButton>
                )}
              </ProfileBox>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <ProfileBox>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#1e3a8a' }}>
                Extra Inaugural Pass (for Spouses only)
                </Typography>
                {profile && profile.inaugural_passes && profile.inaugural_passes.length > 0 ? (
                  profile.inaugural_passes.map((pass, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <ProfileBox>
                        <ProfileAvatar src={pass.photo_base64 ? `${pass.photo_base64}` : null} alt={pass.name} />
                        <ProfileField><strong>Name:</strong> {pass.name}</ProfileField>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                          {pass.is_active && (
                            <DownloadButton
                              variant="contained"
                              startIcon={<DownloadIcon />}
                              onClick={() => handleDownloadSellerPass(pass.uuid, "inaugural")}
                              disabled={downloading}
                            >
                              {downloading ? 'Downloading...' : 'Download Pass'}
                            </DownloadButton>
                          )}
                        </Box>
                      </ProfileBox>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    No Inaugural Pass generated.
                  </Typography>
                )}
                {remainingInauguralPasses > 0 && (
                  <UploadButton
                    variant="contained"
                    startIcon={<AddShoppingCartIcon />}
                    onClick={() => handlePassModal("inaugural")}
                    sx={{ mt: 2 }}
                  >
                    Buy Inaugural Pass
                  </UploadButton>
                )}
              </ProfileBox>
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <ProfileBox>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#1e3a8a' }}>
                2nd Day Networking Pass
                </Typography>
                {profile && profile.networking_passes && profile.networking_passes.length > 0 ? (
                  profile.networking_passes.map((pass, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <ProfileBox>
                        <ProfileAvatar src={pass.photo_base64 ? `${pass.photo_base64}` : null} alt={pass.name} />
                        <ProfileField><strong>Name:</strong> {pass.name}</ProfileField>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                          {pass.is_active && (
                            <DownloadButton
                              variant="contained"
                              startIcon={<DownloadIcon />}
                              onClick={() => handleDownloadSellerPass(pass.uuid, "networking")}
                              disabled={downloading}
                            >
                              {downloading ? 'Downloading...' : 'Download Pass'}
                            </DownloadButton>
                          )}
                        </Box>
                      </ProfileBox>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    No 2nd Day Networking Dinner Pass generated.
                  </Typography>
                )}
                {remainingNetworkPasses > 0 && (
                  <UploadButton
                    variant="contained"
                    startIcon={<AddShoppingCartIcon />}
                    onClick={() => handlePassModal("networking")}
                    sx={{ mt: 2 }}
                  >
                    Buy 2nd Day Networking Dinner Pass
                  </UploadButton>
                )}
              </ProfileBox>
            </TabPanel>
          </Grid>
        </Grid>
        <CartButton
          variant="contained"
          color="primary"
          startIcon={<ShoppingCartIcon />}
          onClick={toggleCart}
        >
          Cart ({cartLength})
        </CartButton>
        <Cart items={cart} open={isCartOpen} onClose={toggleCart} setCart={setCart} fetchProfile={fetchProfile} fetchMainCart={fetchCart} />
      </Container>
      <Footer />
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Create Pass</DialogTitle>

        <DialogContent>
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            Please review all details carefully. Once submitted, they cannot be changed.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Full Name"
            type="text"
            fullWidth
            variant="standard"
            value={additionalPassName}
            onChange={(e) => setAdditionalPassName(e.target.value)}
          />
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUploadIcon />}
            fullWidth
            sx={{ mt: 2 }}
          >
            Upload Photo
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleAdditionalPassPhotoUpload}
            />
          </Button>
          {additionalPassPhoto && (
            <AvatarEditor
              src={additionalPassPhoto}
              width={250}
              height={250}
              border={50}
              color={[255, 255, 255, 0.6]}
              scale={1.2}
              rotate={0}
              exportAsSquare={true}
              exportQuality={0.1}
              exportSize={200}
              onCrop={(croppedImage) => setCroppedImage(croppedImage)}
            />
          )}
          {croppedImage && <Typography>Cropped image: {croppedImage.name}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => (passType === "seller" ? handleCreateSellerPass() : handleAddToCart())}
            color="primary"
            startIcon={passType === "additional" && <AddShoppingCartIcon />}
            disabled={!additionalPassName || !additionalPassPhoto || generatingPass}
          >
            {passType === "seller" ? generatingPass ? 'Generating Pass...' : 'Generate Pass' : (passType === "additional" ? "₹4500 Add to Cart" : "₹4000 Add to Cart")}

          </Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
};

export default Profile;