import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Button,
    Container,
    Grid
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
const PaymentSuccessPage = () => {
    const [searchParams] = useSearchParams();

    const orderId = searchParams.get('orderId') || 'N/A';
    const amount = searchParams.get('amount') || 'N/A';
    const date = searchParams.get('date') || new Date().toLocaleDateString('en-GB');
    const login = searchParams.get('login')
    const navigate = useNavigate();

    const gotoProfile = () => {
        // Implement checkout logic here
        if (login == "seller") {
            navigate('/seller-profile');
        }
        else if (login == "member") {
            navigate('/member-profile');
        }
        else {
            navigate('/');
        }
    };

    return (
        <>
            <Header />
            <Container maxWidth="sm">
                <Box sx={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Card elevation={3}>
                        <CardContent sx={{ textAlign: 'center', py: 4 }}>
                            <CheckCircleIcon sx={{ fontSize: 60, color: 'success.main', mb: 2 }} />
                            <Typography variant="h4" component="div" gutterBottom color="success.main">
                                Payment Successful!
                            </Typography>
                            <Typography variant="body1" color="text.secondary" paragraph>
                                Your payment has been processed successfully. The Passes can be downloaded from the profile
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Order Details:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Order #: {orderId}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Amount: ₹ {amount}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Date: {date}
                            </Typography>
                            <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={gotoProfile}>
                                        Return to Home
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
            <Footer />
        </>
    );
};

export default PaymentSuccessPage;