// components/MemberLogin.jsx
import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Box, Paper, CircularProgress } from '@mui/material';
import api from '../utils/api';
import Header from './Header';
import Footer from './Footer';

const MemberLogin = ({ onOtpSent }) => {
    const [membershipNumber, setMembershipNumber] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSendOtp = async () => {
        setError('');
        setIsLoading(true);
        try {
            await api.post('/sellers/member-send-otp/', { membership_number: membershipNumber });
            onOtpSent(membershipNumber);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError('Error sending OTP. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />
            <Container component="main" maxWidth="sm" sx={{ mt: 8, mb: 2 }}>
                <Paper elevation={3} sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#1e3a8a', fontWeight: 700 }}>
                        Member Login
                    </Typography>
                    <TextField
                        label="Membership Number Eg: 123"
                        variant="outlined"
                        fullWidth
                        value={membershipNumber}
                        onChange={(e) => setMembershipNumber(e.target.value)}
                        error={!!error}
                        helperText={error}
                        sx={{ mt: 2 }}
                        disabled={isLoading}
                    />
                    <Box sx={{ position: 'relative', width: '100%', mt: 3, mb: 2 }}>
                        <Button 
                            variant="contained" 
                            onClick={handleSendOtp} 
                            disabled={isLoading}
                            fullWidth
                            sx={{ 
                                backgroundColor: '#1e3a8a',
                                '&:hover': {
                                    backgroundColor: '#2c5282',
                                },
                            }}
                        >
                            {isLoading ? 'Sending OTP...' : 'Send OTP'}
                        </Button>
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Paper>
            </Container>
            <Footer />
        </Box>
    );
};

export default MemberLogin;