import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import MemberLogin from './components/MemberLogin';
import SellerLogin from './components/SellerLogin';
import MediaLogin from './components/MediaLogin'
import BuyerLogin from './components/BuyerLogin'
import VerifyOtp from './components/VerifyOtp';
import VerifySellerOtp from './components/VerifySellerOtp';
import VerifyMediaOtp from './components/VerifyMediaOtp';
import VerifyBuyerOtp from './components/VerifyBuyerOtp';
import MemberProfile from './components/MemberProfile';
import SellerProfile from './components/SellerProfile';
import BuyerProfile from './components/BuyerProfile';
import MediaProfile from './components/MediaProfile';
import Checkout from './components/Checkout';
import Checkout2 from './components/Checkout2';
import Portal from './components/Portal';
import './App.css';
import PaymentSuccessPage from './components/PaymentSuccessPage';
import PaymentFailed from './components/PaymentFailed';
import PaymentProcessingPage from './components/PaymentProcessingPage';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard';
import VolunteerPass from './components/VolunteerPass';
import PassScanner from './components/PassScanner';
import BuyerAppointments from './components/BuyerAppointments';
import KeralaMarketSurvey from './components/KeralaMarketSurvey';
import ThankYou from './components/ThankYou';
import CreateEntryPass from './components/CreateEntryPass';
import BuyersList from './components/BuyersList';
import Feedback from './components/Feedback';
import TravelDocsForm from './components/Reimbursement';
import ReimbursementForm from './components/ReimbursementForm';
import BuyersReimbursementList from './components/BuyersReimbursementList';
import BuyerDetails from './components/BuyerDetails';
function App() {
    const [membershipNumber, setMembershipNumber] = useState('');
    const [otpSent, setOtpSent] = useState(false);

    const handleOtpSent = (membershipNumber) => {
        setMembershipNumber(membershipNumber);
        setOtpSent(true);
    };

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Portal />} />
                <Route path="/reimburse" element={<ReimbursementForm />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/survey" element={<KeralaMarketSurvey />} />
                <Route path="/thank-you" element={<ThankYou />} />
                <Route path="/success" element={<PaymentSuccessPage />} />
                <Route path="/failed" element={<PaymentFailed />} />
                <Route path="/admin" element={<AdminLogin />} />
                <Route path="/admin-dashboard" element={<AdminDashboard />} />
                <Route path="/buyer-list" element={<BuyersList />} />
                <Route path="/buyer-reimbursement-list" element={<BuyersReimbursementList />} />
                <Route path="/buyer-appointments/:buyerId" element={<BuyerAppointments />} />
                <Route path="/buyer-reimbursement/:buyerId" element={<BuyerDetails />} />
                <Route path="/scan" element={<PassScanner />} />
                <Route path="/volunteer-pass" element={<VolunteerPass />} />
                <Route path="/processing" element={<PaymentProcessingPage />} />
                <Route path="/checkout2" element={<Checkout2 />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="/public" element={<CreateEntryPass />} />
                <Route path="/member-profile" element={<MemberProfile />} />
                <Route path="/seller-profile" element={<SellerProfile />} />
                <Route path="/buyer-profile" element={<BuyerProfile />} />
                <Route path="/media-profile" element={<MediaProfile />} />
                <Route
                    path="/member-login"
                    element={
                        !otpSent ? (
                            <MemberLogin onOtpSent={handleOtpSent} />
                        ) : (
                            <VerifyOtp membershipNumber={membershipNumber} />
                        )
                    }
                />
                <Route
                    path="/seller-login"
                    element={
                        !otpSent ? (
                            <SellerLogin onOtpSent={handleOtpSent} />
                        ) : (
                            <VerifySellerOtp membershipNumber={membershipNumber} />
                        )
                    }
                />
                <Route
                    path="/media-login"
                    element={
                        !otpSent ? (
                            <MediaLogin onOtpSent={handleOtpSent} />
                        ) : (
                            <VerifyMediaOtp membershipNumber={membershipNumber} />
                        )
                    }
                />
                <Route
                    path="/buyer-login"
                    element={
                        !otpSent ? (
                            <BuyerLogin onOtpSent={handleOtpSent} />
                        ) : (
                            <VerifyBuyerOtp membershipNumber={membershipNumber} />
                        )
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;
