import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Paper, Avatar, Button, CircularProgress, TextField, Grid, Badge, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DownloadIcon from '@mui/icons-material/Download';
import Header from './Header';
import Footer from './Footer';
import api from '../utils/api';
import AvatarEditor from 'react-avatar-edit';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import ErrorModal from './ErrorModal';

const ProfileBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'linear-gradient(145deg, #f0f4ff 0%, #e0e8ff 100%)',
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
}));


const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(20),
  marginBottom: theme.spacing(2),
  border: `4px solid ${theme.palette.background.paper}`,
  boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
}));

const ProfileField = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.5),
  background: 'rgba(255,255,255,0.6)',
  borderRadius: '8px',
  width: '100%',
  textAlign: 'center',
}));

const UploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const DownloadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
}));

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [additionalPassName, setAdditionalPassName] = useState('');
  const [additionalPassPhoto, setAdditionalPassPhoto] = useState(null);
  const [passName, setPassName] = useState('');
  const [passPhoto, setPassPhoto] = useState(null);
  const [inauguralPassCount, setInauguralPassCount] = useState(0);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [generatingPass, setGeneratingPass] = useState(false);
  const navigate = useNavigate();



  useEffect(() => {
    fetchProfile();
  }, []);

  const handleClose = () => setError(null);

  const fetchProfile = async () => {
    try {
      const response = await api.get('/sellers/member-profile/');
      setProfile(response.data);
      setInauguralPassCount(response.data.inaugural_pass_count || 0);
      setLoading(false);
    } catch (err) {
      setError('Failed to load profile. Please try again later.');
      setLoading(false);
    }
  };

  const handlePhotoUpload = async () => {
    if (!croppedImage) return;

    const formData = new FormData();
    formData.append('photo', croppedImage);
    formData.append('pass_name', passName);

    setUploading(true);
    try {
      await api.put('/sellers/member-update/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await fetchProfile(); // Refresh profile data
      setIsEditing(false); // Hide the AvatarEditor component
    } catch (error) {
      if (error.response && error.response.data && error.response.data.photo) {
        setError(error.response.data.photo);
      } else {
        setError('Failed to upload photo. Please try again.');
      }
    } finally {
      setUploading(false);
    }
  };

  const handleAdditionalPassPhotoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setAdditionalPassPhoto(reader.result);
    });
    reader.readAsDataURL(file);
  };
  const handlePassPhotoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setPassPhoto(reader.result);
    });
    reader.readAsDataURL(file);
  };

  const handleAddToCart = () => {
    if (additionalPassName && additionalPassPhoto) {
      if (inauguralPassCount >= 1) {
        setError('You already have an inaugural pass.');
        return;
      }

      setGeneratingPass(true);
      const formData = new FormData();
      formData.append('photo', croppedImage);
      formData.append('name', additionalPassName);

      api
        .post('sellers/member-inaugural-pass/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          setError('');
          fetchProfile(); // Call fetchProfile once API call is successful
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.photo) {
            setError(error.response.data.photo);
          } else {
            setError('Failed to upload photo. Please try again.');
          }
        })
        .finally(() => {
          setGeneratingPass(false);
        });

      setAdditionalPassName('');
      setAdditionalPassPhoto(null);
    }
  };


  const handleDownloadPass = async () => {
    setDownloading(true);
    try {
      const response = await api.get('/sellers/member-pass/', {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'member_pass.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setError('Failed to download pass. Please try again.');
    } finally {
      setDownloading(false);
    }
  };

  const handleDownloadInauguralPass = async (uuid) => {
    setDownloading(true);
    try {
      const response = await api.get('/sellers/download-inaugural-pass/' + uuid, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'member_pass.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setError('Failed to download pass. Please try again.');
    } finally {
      setDownloading(false);
    }
  };

  const handleCheckout = () => {
    // Implement checkout logic here
    navigate('/checkout');

  };

  if (loading) {
    return <CircularProgress />;
  }


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Container component="main" maxWidth="lg" sx={{ mt: 8, mb: 2 }}>
        {error &&
          <ErrorModal error={error} handleClose={handleClose} />
        }
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            {profile &&
              <ProfileBox>
                <ProfileAvatar
                  src={profile.photo ? profile.photo : null}
                  alt={profile.name}
                >

                </ProfileAvatar>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#1e3a8a' }}>
                  {profile.name}
                </Typography>
                <ProfileField><strong>Email:</strong> {profile.email}</ProfileField>
                <ProfileField><strong>Phone:</strong> {profile.phone}</ProfileField>
                <ProfileField><strong>Membership Number:</strong> {profile.membership_number}</ProfileField>
                <ProfileField><strong>Organization:</strong> {profile.organization_name}</ProfileField>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                </Box>
              </ProfileBox>
            }
          </Grid>
          <Grid item xs={12} md={4}>
            <ProfileBox>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#1e3a8a' }}>
                Member Passes
              </Typography>
              {profile && profile.photo ? (
                <>
                  <ProfileAvatar src={profile.photo? `${profile.photo}` : null} alt={profile.name} />
                  <ProfileField><strong>Name:</strong> {profile.pass_name}</ProfileField>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <DownloadButton
                      variant="contained"
                      startIcon={<DownloadIcon />}
                      onClick={handleDownloadPass}
                      disabled={downloading}
                    >
                      {downloading ? 'Downloading...' : 'Download Pass'}
                    </DownloadButton>
                  </Box>
                </>
              ) : (
                <Box sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    label="Name for Member Pass"
                    variant="outlined"
                    value={passName}
                    onChange={(e) => setPassName(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <UploadButton
                    variant="outlined"
                    component="label"
                    fullWidth
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Photo for Member Pass
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handlePassPhotoUpload}
                    />
                  </UploadButton>
                  {passPhoto && (
                    <AvatarEditor
                      src={passPhoto}
                      width={250}
                      height={250}
                      border={50}
                      color={[255, 255, 255, 0.6]}
                      scale={1.2}
                      rotate={0}
                      exportAsSquare={true}
                      exportQuality={0.1}
                      exportSize={200}
                      onCrop={(croppedImage) => setCroppedImage(croppedImage)}
                    />
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handlePhotoUpload()}
                    disabled={!passName || !passPhoto || uploading}
                    sx={{ mt: 2 }}
                  >
                    Create
                  </Button>
                  <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                    Please review all details carefully. Once submitted, they cannot be changed.
                  </Typography>
                </Box>
              )}
            </ProfileBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <ProfileBox>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#1e3a8a' }}>
              Extra Inaugural Pass (for Spouses only)
              </Typography>
              {profile && profile.inaugural_passes && profile.inaugural_passes.length > 0 ? (
                profile.inaugural_passes.map((pass, index) => (

                  <>
                    <ProfileAvatar src={pass.photo_base64 ? `${pass.photo_base64}` : null} alt={pass.name} />
                    <ProfileField><strong>Name:</strong> {pass.name}</ProfileField>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      {pass.is_active ?
                        <DownloadButton
                          variant="contained"
                          startIcon={<DownloadIcon />}
                          onClick={() => handleDownloadInauguralPass(pass.uuid)}
                          disabled={downloading}
                        >
                          {downloading ? 'Downloading...' : 'Download Pass'}
                        </DownloadButton>
                        :
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          startIcon={<AddShoppingCartIcon />}
                          onClick={handleCheckout}
                          sx={{ mt: 2 }}
                        >
                          ₹4000 Checkout
                        </Button>
                      }
                    </Box>
                  </>
                ))
              ) : (
                <Box sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    label="Name for Inaugural Pass"
                    variant="outlined"
                    value={additionalPassName}
                    onChange={(e) => setAdditionalPassName(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <UploadButton
                    variant="outlined"
                    component="label"
                    fullWidth
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Photo for Additional Pass
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleAdditionalPassPhotoUpload}
                    />
                  </UploadButton>
                  {additionalPassPhoto && (
                    <AvatarEditor
                      src={additionalPassPhoto}
                      width={250}
                      height={250}
                      border={50}
                      color={[255, 255, 255, 0.6]}
                      scale={1.2}
                      rotate={0}
                      exportAsSquare={true}
                      exportQuality={0.1}
                      exportSize={200}
                      onCrop={(croppedImage) => setCroppedImage(croppedImage)}
                    />
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<AddShoppingCartIcon />}
                    onClick={handleAddToCart}
                    disabled={!additionalPassName || !additionalPassPhoto}
                    sx={{ mt: 2 }}
                  >
                    Upload Photo
                  </Button>
                  <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                    Please review all details carefully. Once submitted, they cannot be changed.
                  </Typography>
                </Box>
              )}
            </ProfileBox>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default Profile;