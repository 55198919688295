import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
} from '@mui/material';
import CreatePass from './CreatePass';
import PassList from './PassList';

const EntryPass = () => {
  const [open, setOpen] = useState(false);
  const [refreshList, setRefreshList] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePassCreated = () => {
    setOpen(false);
    setRefreshList(true);
    setTimeout(() => setRefreshList(false), 0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Entry Passes
        </Typography>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Create New Pass
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <CreatePass onPassCreated={handlePassCreated} />

          </Box>
        </Fade>
      </Modal>
      <PassList key={refreshList} />
    </Box>
  );
};

export default EntryPass;
