import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button, TextField, Container, Typography, Box, Paper, CircularProgress,
  Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Rating, Select, MenuItem
} from '@mui/material';
import Header from './Header';
import api from '../utils/api';

const Feedback = () => {
  const [formData, setFormData] = useState({
    overall_mart_experience: 0,
    mart_facilities_rating: 0,
    meetings_quality_rating: 0,
    meetings_usefulness: 0,
    meetings_improvement_suggestions: '',
    app_usefulness: 0,
    app_user_friendliness: 0,
    app_feedback: '',
    overall_satisfaction: 0,
    improvement_suggestions: '',
    would_attend_future: null,
    additional_comments: '',
    buyer: '',
    seller: '',
    member: '',
    media: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userType, setUserType] = useState('');
  const [showUserTypeSelection, setShowUserTypeSelection] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const buyerId = searchParams.get('buyer_id');
    const stallId = searchParams.get('stall');

    if (buyerId) {
      setFormData(prevData => ({ ...prevData, buyer: buyerId }));
    } else if (stallId) {
      setFormData(prevData => ({ ...prevData, seller: stallId }));
    } else {
      setShowUserTypeSelection(true);
    }
  }, [location]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleRatingChange = (name) => (event, newValue) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: newValue
    }));
  };

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');
    try {
      await api.post('buyers/rating/', formData);
      navigate('/thank-you');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError('An error occurred while submitting the feedback. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Container component="main" maxWidth="md" sx={{ mt: 8, mb: 2 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom>
            Kerala Travel Mart 2024 Feedback
          </Typography>
          <Typography variant="body1" paragraph>
            Greetings from Kerala Travel Mart. Thank you for attending KTM 2024. We value your feedback to help us enhance future events. Please take a few moments to share your thoughts on the following aspects of the event.
          </Typography>
          {showUserTypeSelection && (
            <Box sx={{ mb: 4 }}>
              <FormControl fullWidth>
                <FormLabel>Select User Type</FormLabel>
                <Select
                  value={userType}
                  onChange={handleUserTypeChange}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="" disabled>Select user type</MenuItem>
                  <MenuItem value="media">Media</MenuItem>
                  <MenuItem value="member">Member</MenuItem>
                </Select>
              </FormControl>
              {userType && (
                <TextField
                  name={userType === 'media' ? 'media' : 'member'}
                  label={`${userType === 'media' ? 'Media' : 'Member'} ID`}
                  fullWidth
                  margin="normal"
                  value={formData[userType]}
                  onChange={handleChange}
                />
              )}
            </Box>
          )}
          <form onSubmit={handleSubmit}>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              1. Mart Experience
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography gutterBottom>How would you rate your overall experience at the KTM 2024 mart?</Typography>
              <Rating
                name="overall_mart_experience"
                value={formData.overall_mart_experience}
                onChange={handleRatingChange('overall_mart_experience')}
                size="large"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography gutterBottom>Were the mart facilities and organization up to your expectations?</Typography>
              <Rating
                name="mart_facilities_rating"
                value={formData.mart_facilities_rating}
                onChange={handleRatingChange('mart_facilities_rating')}
                size="large"
              />
            </Box>

            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
              2. Meetings & Networking
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography gutterBottom>How would you rate the quality of meetings and networking opportunities?</Typography>
              <Rating
                name="meetings_quality_rating"
                value={formData.meetings_quality_rating}
                onChange={handleRatingChange('meetings_quality_rating')}
                size="large"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography gutterBottom>Were the scheduled meetings useful for your business objectives?</Typography>
              <Rating
                name="meetings_usefulness"
                value={formData.meetings_usefulness}
                onChange={handleRatingChange('meetings_usefulness')}
                size="large"
              />
            </Box>
            <TextField
              name="meetings_improvement_suggestions"
              label="How could the meeting arrangements be improved in the future?"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.meetings_improvement_suggestions}
              onChange={handleChange}
            />

            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
              3. Mobile App Experience
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography gutterBottom>Did you find the KTM 2024 mobile app useful?</Typography>
              <Rating
                name="app_usefulness"
                value={formData.app_usefulness}
                onChange={handleRatingChange('app_usefulness')}
                size="large"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography gutterBottom>How would you rate the app's user-friendliness and functionality?</Typography>
              <Rating
                name="app_user_friendliness"
                value={formData.app_user_friendliness}
                onChange={handleRatingChange('app_user_friendliness')}
                size="large"
              />
            </Box>
            <TextField
              name="app_feedback"
              label="Were there any features of the app that you found particularly helpful or that need improvement?"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.app_feedback}
              onChange={handleChange}
            />

            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
              4. Overall Experience
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography gutterBottom>How satisfied are you with your overall experience at KTM 2024?</Typography>
              <Rating
                name="overall_satisfaction"
                value={formData.overall_satisfaction}
                onChange={handleRatingChange('overall_satisfaction')}
                size="large"
              />
            </Box>
            <TextField
              name="improvement_suggestions"
              label="Do you have any suggestions for improving future KTM events?"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.improvement_suggestions}
              onChange={handleChange}
            />

            <FormControl component="fieldset" margin="normal" fullWidth>
              <FormLabel component="legend">Would you like to attend future editions of KTM?</FormLabel>
              <RadioGroup
                name="would_attend_future"
                value={formData.would_attend_future}
                onChange={handleChange}
              >
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
              5. Additional Comments
            </Typography>
            <TextField
              name="additional_comments"
              label="Please feel free to share any additional thoughts or suggestions"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.additional_comments}
              onChange={handleChange}
            />

            <Box sx={{ mt: 3, position: 'relative' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading || (showUserTypeSelection && (!userType || !formData[userType]))}
                fullWidth
                sx={{
                  backgroundColor: '#1e3a8a',
                  '&:hover': {
                    backgroundColor: '#2c5282',
                  },
                }}
              >
                {isLoading ? 'Submitting...' : 'Submit Feedback'}
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </form>
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default Feedback;
