import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Button,
    Container,
    Grid
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

const PaymentFailed = () => {
    const [searchParams] = useSearchParams();

    const orderId = searchParams.get('orderId') || 'N/A';
    const errorMessage = searchParams.get('error') || 'An unknown error occurred';
    const date = searchParams.get('date') || new Date().toLocaleDateString('en-GB');
    const login = searchParams.get('login')
    const navigate = useNavigate();

    const gotoProfile = () => {
        if (login === "seller") {
            navigate('/seller-profile');
        } else if (login === "member") {
            navigate('/member-profile');
        } else {
            navigate('/');
        }
    };


    return (
        <>
            <Header />
            <Container maxWidth="sm">
                <Box sx={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Card elevation={3}>
                        <CardContent sx={{ textAlign: 'center', py: 4 }}>
                            <ErrorIcon sx={{ fontSize: 60, color: 'error.main', mb: 2 }} />
                            <Typography variant="h4" component="div" gutterBottom color="error.main">
                                Payment Failed
                            </Typography>
                            <Typography variant="body1" color="text.secondary" paragraph>
                                We're sorry, but your payment could not be processed. Please try again or contact support.
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Order #: {orderId}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Error: {errorMessage}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Date: {date}
                            </Typography>
                            <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                                <Grid item>
                                    <Button variant="outlined" color="primary" onClick={gotoProfile}>
                                        Return to Home
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
            <Footer />
        </>
    );
};

export default PaymentFailed;