import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TextField, 
  Button, 
  Box,
  Typography,
  CircularProgress,
  Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import api from '../utils/api';

const BuyersList = () => {
  const [buyers, setBuyers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchBuyers = async (reset = false) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('buyers/buyers-compact/', {
        params: {
          search: search,
          limit: 10,
          offset: reset ? 0 : (page - 1) * 10,
        }
      });
      setBuyers(prevBuyers => reset ? response.data.results : [...prevBuyers, ...response.data.results]);
      setHasMore(response.data.next !== null);
      if (reset) setPage(1);
    } catch (err) {
      setError('Failed to fetch buyers. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBuyers(true);
  }, [search]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
    fetchBuyers();
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Buyers List
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        value={search}
        onChange={handleSearchChange}
        fullWidth
        margin="normal"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Buyer ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {buyers.map((buyer) => (
              <TableRow key={buyer.buyer_id}>
                <TableCell>{buyer.buyer_id}</TableCell>
                <TableCell>
                  <Link component={RouterLink} to={`/buyer-appointments/${buyer.buyer_id}`}>
                    {buyer.name}
                  </Link>
                </TableCell>
                <TableCell>{buyer.organization_name}</TableCell>
                <TableCell>{buyer.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}
      {hasMore && !loading && (
        <Button onClick={handleLoadMore} variant="contained" sx={{ mt: 2 }}>
          Load More
        </Button>
      )}
    </Box>
  );
};

export default BuyersList;