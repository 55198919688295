import React, { useState } from 'react';
import api from '../utils/api';
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress
} from '@mui/material';
import Avatar from 'react-avatar-edit';

const categories = [
  'Managing Committee',
  'Organizing Committee 2024',
  'Past President - KTM',
  'Former Secretary KTM',
  'Speaker',
  'Invitee',
  'Media',
  'Crew',
  'Volunteer',
  'Sponsor',
  'Transport Team', // Added new category
  'Food Court Crew',
  'House Keeping',
  'India Tourism',
  'Kerala Tourism',
  'Software Support',
  'Entry Pass',
  'PR & Media - MD Niche',
  'Wifi Support',
  'Event Team'
];

const CreatePass = ({ onPassCreated }) => {
  const [formData, setFormData] = useState({
    name: '',
    category: '',
    pass_type: '',
    title: '', // Added title to the initial state
    photo: null
  });
  const [preview, setPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
      // If the changed field is category, update pass_type as well
      ...(name === 'category' ? { pass_type: value } : {})
    }));
  };

  const onClose = () => {
    setPreview(null);
  };

  const onCrop = (preview) => {
    setPreview(preview);
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 2000000) {
      alert("File is too big!");
      elem.target.value = "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    setSuccess('');

    try {
      const response = await api.post('/buyers/entry-passes/', {
        ...formData,
        photo: preview
      });
      setSuccess('Pass created successfully!');
      setFormData({
        name: '',
        category: '',
        pass_type: '',
        title: '',
        photo: null
      });
      setPreview(null);
      onPassCreated();
    } catch (error) {
      setError('Failed to create pass. Please try again.');
      console.error('Error creating pass:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box 
      component="form" 
      onSubmit={handleSubmit} 
      sx={{ 
        maxWidth: 400, 
        margin: 'auto', 
        maxHeight: '80vh', // Limit the height
        overflowY: 'auto', // Add vertical scrolling
        padding: 2, // Add some padding
      }}
    >
      <Typography variant="h5" gutterBottom>
        Create New Pass
      </Typography>
      <TextField
        fullWidth
        margin="normal"
        name="name"
        label="Name"
        value={formData.name}
        onChange={handleInputChange}
        required
      />
      <TextField
        fullWidth
        margin="normal"
        name="title"
        label="Title"
        value={formData.title}
        onChange={handleInputChange}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Category</InputLabel>
        <Select
          name="category"
          value={formData.category}
          onChange={handleInputChange}
          required
        >
          {categories.map((category) => (
            <MenuItem key={category} value={category}>{category}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        margin="normal"
        name="pass_type"
        label="Badge"
        value={formData.pass_type}
        onChange={handleInputChange}
        required
      />
      <Box sx={{ my: 2 }}>
        <Avatar
          width={200}
          height={200}
          onCrop={onCrop}
          onClose={onClose}
          exportAsSquare={true}
          exportQuality={0.1}
          exportSize={200}
          onBeforeFileLoad={onBeforeFileLoad}
          src={null}
        />
      </Box>
      {preview && (
        <Box sx={{ my: 2 }}>
          <Typography variant="subtitle1">Preview:</Typography>
          <img src={preview} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
        </Box>
      )}
      <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'background.paper', pt: 2 }}>
        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          fullWidth 
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <>
              <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
              Creating Pass...
            </>
          ) : (
            'Create Pass'
          )}
        </Button>
      </Box>
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      {success && (
        <Typography color="success" sx={{ mt: 2 }}>
          {success}
        </Typography>
      )}
    </Box>
  );
};

export default CreatePass;