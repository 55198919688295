import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Chip,
} from '@mui/material';
import {
  Person,
  Category,
  Title,
  Business,
  Event,
  CheckCircle,
  Cancel,
  Refresh,
} from '@mui/icons-material';
import api from '../utils/api';

const PassScanner = ({ accessPointId, onBack }) => {
  const [qrCode, setQrCode] = useState('');
  const [passData, setPassData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [entryCount, setEntryCount] = useState(0);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    fetchEntryCount();
  }, []);

  const fetchEntryCount = async () => {
    try {
      const response = await api.get('buyers/access-point-entry-count/');
      const counts = response.data;
      const currentAccessPointCount = counts.find(
        (item) => item.access_point__id === accessPointId
      );
      setEntryCount(currentAccessPointCount ? currentAccessPointCount.count : 0);
    } catch (error) {
      console.error('Error fetching entry count:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setPassData(null);

    try {
      const response = await api.post('/buyers/verify-pass/', {
        qr_code: qrCode,
        access_point_id: accessPointId,
      });
      setPassData(response.data);
      fetchEntryCount(); // Refresh the entry count after a successful scan
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred while verifying the pass.');
    } finally {
      setLoading(false);
      setQrCode('');
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  return (
    <Box>
      <Button onClick={onBack} variant="outlined" sx={{ mb: 2 }}>
        Back to Access Points
      </Button>
      <Typography variant="h5" gutterBottom>
        Scan Pass
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Total Entries: {entryCount}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Scan QR Code"
          value={qrCode}
          onChange={(e) => setQrCode(e.target.value)}
          margin="normal"
          inputRef={inputRef}
          autoFocus
        />
        <Button type="submit" variant="contained" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Verify'}
        </Button>
      </form>
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      {passData && (
        <Card sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {passData.photo && (
              <Grid item xs={12} sm={4}>
                <CardMedia
                  component="img"
                  image={passData.photo}
                  alt="Pass Photo"
                  sx={{ height: '100%', objectFit: 'cover' }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={passData.photo ? 8 : 12}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Pass Information
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Chip
                      label={passData.pass_type}
                      color="primary"
                      sx={{ mb: 2, mr: 1 }}
                    />
                    {passData.created === false && (
                      <Chip
                        icon={<Refresh />}
                        label="Already Scanned"
                        color="warning"
                        sx={{ mb: 2 }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Person sx={{ mr: 1 }} /> {passData.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Event sx={{ mr: 1 }} /> {new Date(passData.created_at).toLocaleString()}
                    </Typography>
                  </Grid>
                  {passData.category && (
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Category sx={{ mr: 1 }} /> {passData.category}
                      </Typography>
                    </Grid>
                  )}
                  {passData.title && (
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Title sx={{ mr: 1 }} /> {passData.title}
                      </Typography>
                    </Grid>
                  )}
                  {passData.organization_name && (
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Business sx={{ mr: 1 }} /> {passData.organization_name}
                      </Typography>
                    </Grid>
                  )}
                  {passData.hasOwnProperty('is_allowed') && (
                    <Grid item xs={12}>
                      <Chip
                        icon={passData.is_allowed ? <CheckCircle /> : <Cancel />}
                        label={passData.is_allowed ? 'Access Allowed' : 'Access Denied'}
                        color={passData.is_allowed ? 'success' : 'error'}
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      )}
    </Box>
  );
};

export default PassScanner;