import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Container, Typography, Box, Paper, CircularProgress } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import api from '../utils/api';
import '../styles/VerifyOtp.css';

const VerifySellerOtp = ({ membershipNumber }) => {
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleVerifyOtp = async () => {
        setIsLoading(true);
        setError('');
        try {
            const response = await api.post('/sellers/verify-otp/', {
                email: membershipNumber,
                otp: otp,
            });
            const { access, refresh } = response.data;
            localStorage.setItem('accessToken', access);
            localStorage.setItem('refreshToken', refresh);
            localStorage.setItem('type', "seller");
            navigate('/seller-profile');
        } catch (error) {
            setError('Invalid OTP or OTP expired. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />
            <Container component="main" maxWidth="sm" sx={{ mt: 8, mb: 2 }}>
                <Paper elevation={3} sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h5" gutterBottom>
                        Enter OTP
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2, textAlign: 'center', color: 'text.secondary' }}>
                        Please enter the OTP that has been sent to your registered email address.
                    </Typography>
                    <TextField
                        label="OTP"
                        variant="outlined"
                        fullWidth
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        error={!!error}
                        helperText={error}
                        className="verify-input"
                        disabled={isLoading}
                    />
                    <Box sx={{ position: 'relative', width: '100%', mt: 3, mb: 2 }}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleVerifyOtp} 
                            disabled={isLoading}
                            fullWidth
                            sx={{ 
                                backgroundColor: '#1e3a8a',
                                '&:hover': {
                                    backgroundColor: '#2c5282',
                                },
                            }} 
                            className="verify-button"
                        >
                            {isLoading ? 'Verifying...' : 'Verify OTP'}
                        </Button>
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Paper>
            </Container>
            <Footer />
        </Box>
    );
};

export default VerifySellerOtp;