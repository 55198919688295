import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, Box } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Header from './Header';
import Footer from './Footer';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1b5e20', // dark green
    },
    background: {
      default: '#e8f5e9', // very light green
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
  },
});

const LandingPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Header />
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.default',
            p: 4,
            backgroundImage: 'url("https://source.unsplash.com/random/1920x1080/?kerala,backwaters")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Card 
            sx={{ 
              maxWidth: 600, 
              width: '100%', 
              boxShadow: 3, 
              borderRadius: 4, 
              overflow: 'hidden',
              bgcolor: 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <CardContent sx={{ p: 0 }}>
              <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 4, px: 2, textAlign: 'center' }}>
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 700 }}>
                  KTM 2024
                </Typography>
              </Box>
              <Box sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CalendarTodayIcon sx={{ fontSize: 60, color: 'primary.main', mb: 3 }} />
                <Typography variant="body1" align="center" sx={{ mb: 2 }}>
                The passes for KTM 2024 can be generated (as per eligibility) from 
                </Typography>
                <Typography variant="h5" align="center" sx={{ fontWeight: 600, color: 'primary.main' }}>
                  September 14th, 2024  onwards
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;