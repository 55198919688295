// components/Header.jsx
import React from 'react';
import { AppBar, Toolbar, Typography, Container } from '@mui/material';

import logo from '../images/logo.png'
const Header = () => {
  return (
    <AppBar position="static" sx={{ backgroundColor: 'white' }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <img src={logo} style={{width: 140,padding: 10}}/>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, fontWeight: 700, letterSpacing: '.1rem', color:'black' }}
          >
            KTM 2024
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;