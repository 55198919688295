import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Checkbox,
  FormGroup,
  FormControlLabel,
  DialogContentText,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import api from '../utils/api';
import PassScanner from './PassScanner';

const AccessPoints = () => {
  const [accessPoints, setAccessPoints] = useState([]);
  const [open, setOpen] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [name, setName] = useState('');
  const [availablePassTypes, setAvailablePassTypes] = useState([]);
  const [selectedPassTypes, setSelectedPassTypes] = useState([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedAccessPoint, setSelectedAccessPoint] = useState(null);

  useEffect(() => {
    fetchAccessPoints();
    fetchPassTypes();
  }, []);

  const fetchAccessPoints = async () => {
    try {
      const response = await api.get('/buyers/access-points/');
      setAccessPoints(response.data);
    } catch (error) {
      console.error('Error fetching access points:', error);
    }
  };

  const fetchPassTypes = async () => {
    try {
      const response = await api.get('/buyers/unique-pass-types');
      setAvailablePassTypes(response.data);
    } catch (error) {
      console.error('Error fetching pass types:', error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setEditingId(null);
    setName('');
    setSelectedPassTypes([]);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingId(null);
  };

  const handleEdit = (accessPoint) => {
    setEditingId(accessPoint.id);
    setName(accessPoint.name);
    setSelectedPassTypes(accessPoint.pass_types);
    setOpen(true);
  };

  const handleSubmit = async () => {
    const data = { name, pass_types: selectedPassTypes };

    try {
      if (editingId) {
        await api.put(`/buyers/access-points/${editingId}/`, data);
      } else {
        await api.post('/buyers/access-points/', data);
      }
      fetchAccessPoints();
      handleClose();
    } catch (error) {
      console.error('Error saving access point:', error);
    }
  };

  const handlePassTypeChange = (passType) => {
    setSelectedPassTypes(prev =>
      prev.includes(passType)
        ? prev.filter(type => type !== passType)
        : [...prev, passType]
    );
  };

  const handleDeleteConfirmOpen = (id) => {
    setDeleteId(id);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirmClose = () => {
    setDeleteConfirmOpen(false);
    setDeleteId(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      await api.delete(`/buyers/access-points/${deleteId}/`);
      fetchAccessPoints();
      handleDeleteConfirmClose();
    } catch (error) {
      console.error('Error deleting access point:', error);
    }
  };

  const handleDelete = async (id) => {
    handleDeleteConfirmOpen(id);
  };

  const handleAccessPointClick = (accessPoint) => {
    setSelectedAccessPoint(accessPoint);
  };

  const handleBackToList = () => {
    setSelectedAccessPoint(null);
  };

  if (selectedAccessPoint) {
    return <PassScanner accessPointId={selectedAccessPoint.id} onBack={handleBackToList} />;
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Access Points
      </Typography>
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpen}>
        Add Access Point
      </Button>
      <List>
        {accessPoints.map((accessPoint) => (
          <ListItem
            key={accessPoint.id}
            button
            onClick={() => handleAccessPointClick(accessPoint)}
            secondaryAction={
              <Box>
                <IconButton edge="end" aria-label="edit" onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(accessPoint);
                }}>
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteConfirmOpen(accessPoint.id);
                }}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            }
          >
            <ListItemText
              primary={accessPoint.name}
              secondary={
                <Box>
                  {accessPoint.pass_types.map((type, index) => (
                    <Chip key={index} label={type} size="small" style={{ marginRight: 4 }} />
                  ))}
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingId ? 'Edit Access Point' : 'Add Access Point'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Typography variant="subtitle1" style={{ marginTop: 16 }}>Pass Types:</Typography>
          <FormGroup>
            {availablePassTypes.map((passType) => (
              <FormControlLabel
                key={passType}
                control={
                  <Checkbox
                    checked={selectedPassTypes.includes(passType)}
                    onChange={() => handlePassTypeChange(passType)}
                  />
                }
                label={passType}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{editingId ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this access point? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmClose}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AccessPoints;