import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  TextField,
  MenuItem,
  TablePagination,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import api from '../utils/api';

const PassList = () => {
  const [passes, setPasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [passTypeFilter, setPassTypeFilter] = useState('');
  const [titleFilter, setTitleFilter] = useState('');
  const [passTypes, setPassTypes] = useState([]);
  const [titles, setTitles] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [downloadingId, setDownloadingId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const categories = [
    'Managing Committee',
    'Organizing Committee 2024',
    'Past President - KTM',
    'Former Secretary KTM',
    'Speaker',
    'Invitee',
    'Media',
    'Crew',
    'Volunteer',
    'Transport Team', // New category
    'Food Court Crew',
    'House Keeping',
    'Sponsor',
    'India Tourism',
    'Kerala Tourism',
    'Software Support',
    'Entry Pass',
    'PR & Media - MD Niche',
    'Wifi Support',
    'Event Team'
  ];

  useEffect(() => {
    fetchPasses();
    fetchPassTypes();
    fetchTitles();
  }, [categoryFilter, passTypeFilter, titleFilter, searchQuery, page, rowsPerPage]);

  const fetchPasses = async () => {
    setLoading(true);
    try {
      let url = '/buyers/entry-passes/list/';
      const params = new URLSearchParams();
      if (categoryFilter) params.append('category', categoryFilter);
      if (passTypeFilter) params.append('pass_type', passTypeFilter);
      if (titleFilter) params.append('title', titleFilter);
      if (searchQuery) params.append('search', searchQuery);
      params.append('limit', rowsPerPage);
      params.append('offset', page * rowsPerPage);
      if (params.toString()) url += `?${params.toString()}`;

      const response = await api.get(url);
      setPasses(response.data.results);
      setTotalCount(response.data.count);
    } catch (err) {
      setError('Failed to fetch passes');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchPassTypes = async () => {
    try {
      const response = await api.get('/buyers/unique-pass-types');
      setPassTypes(response.data);
    } catch (err) {
      console.error('Failed to fetch pass types', err);
    }
  };

  const fetchTitles = async () => {
    try {
      const response = await api.get('/buyers/unique-titles');
      setTitles(response.data);
    } catch (err) {
      console.error('Failed to fetch titles', err);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownload = async (id) => {
    setDownloadingId(id);
    try {
      const response = await api.get(`/buyers/entry-passes/download/${id}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `pass_${id}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setDownloadingId(null);
    }
  };

  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      setSearchQuery(event.target.value);
      setPage(0);
    }
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap' }}>
        <TextField
          label="Search"
          variant="outlined"
          onKeyPress={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearchQuery(document.getElementById('search-input').value)}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          id="search-input"
          sx={{ minWidth: 200, flexGrow: 1 }}
        />
        <TextField
          select
          label="Filter by Category"
          value={categoryFilter}
          onChange={(e) => setCategoryFilter(e.target.value)}
          sx={{ minWidth: 200 }}
        >
          <MenuItem value="">All Categories</MenuItem>
          {categories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Filter by Pass Type"
          value={passTypeFilter}
          onChange={(e) => setPassTypeFilter(e.target.value)}
          sx={{ minWidth: 200 }}
        >
          <MenuItem value="">All Pass Types</MenuItem>
          {passTypes.map((passType) => (
            <MenuItem key={passType} value={passType}>
              {passType}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Filter by Title"
          value={titleFilter}
          onChange={(e) => setTitleFilter(e.target.value)}
          sx={{ minWidth: 200 }}
        >
          <MenuItem value="">All Titles</MenuItem>
          {titles.map((title) => (
            <MenuItem key={title} value={title}>
              {title}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Pass Type</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Download</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {passes.map((pass) => (
              <TableRow key={pass.uuid}>
                <TableCell>{pass.name}</TableCell>
                <TableCell>{pass.category}</TableCell>
                <TableCell>{pass.pass_type}</TableCell>
                <TableCell>{pass.title}</TableCell>
                <TableCell>
                  {new Date(pass.created_at).toLocaleString()}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleDownload(pass.uuid)}
                    disabled={downloadingId === pass.uuid}
                  >
                    {downloadingId === pass.uuid ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      'Download'
                    )}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default PassList;
