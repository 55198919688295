import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Paper, Container, Grid, CircularProgress } from '@mui/material';
import axios from 'axios';
import api from '../utils/api';
import Header from './Header';
import Footer from './Footer';

const Checkout = () => {
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        address: ''
    });

    const [error, setError] = useState({
        firstName: false,
        lastName: false,
        email: false,
        address: false,
    });

    const [cart, setCart] = useState([]);
    const [subtotal, setSubtotal] = useState(0);
    const [gst, setGst] = useState(0);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    // Fetch cart data from API on component mount
    useEffect(() => {
        const fetchCartData = async () => {
            try {
                setLoading(true);
                const response = await api.get('sellers/cart/');
                setCart(response.data);
                const cartSubtotal = response.data.reduce((acc, item) => acc + item.price, 0);
                setSubtotal(cartSubtotal);
                const gstAmount = cartSubtotal * 0.18;
                setGst(gstAmount);
                setTotal(cartSubtotal + gstAmount);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch cart items:', error);
                setLoading(false);
            }
        };

        fetchCartData();
    }, []);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        // Validate fields
        const newError = {
            firstName: form.firstName === '',
            lastName: form.lastName === '',
            email: form.email === '',
            address: form.address === ''
        };

        setError(newError);

        const isValid = Object.values(newError).every((err) => !err);

        if (isValid) {
            // Prepare form data
            const formData = {
                buyerFirstName: form.firstName,
                buyerLastName: form.lastName,
                buyerEmail: form.email,
                address: form.address,
                gst:form.gst
            };

            try {
                const response = await api.post('/sellers/checkout/', formData);
                console.log("Checkout data:", response.data);

                const airpayform = document.createElement("form");
                airpayform.method = "POST";
                airpayform.action = "https://payments.airpay.co.in/pay/index.php";

                // Append form fields
                const postData = {
                    buyerEmail: form.email,
                    buyerFirstName: form.firstName,
                    buyerLastName: form.lastName,
                    orderid: response.data.orderid,
                    amount: total.toFixed(2),  // Use the total with GST
                    UID: response.data.orderid,
                    mercid: "320677",
                    kittype: "server_side_sdk",
                    currency: 356,
                    isocurrency: "INR",
                    txnsubtype: 2,
                    privatekey: response.data.privatekey,
                    checksum: response.data.checksum,
                }
                for (const key in postData) {
                    const input = document.createElement("input");
                    input.type = "text";
                    input.name = key;
                    input.value = postData[key];
                    airpayform.appendChild(input);
                }

                document.body.appendChild(airpayform);
                airpayform.submit();
                setLoading(false);
                // Redirect or show success message
            } catch (error) {
                console.error("Error during checkout:", error);
                setLoading(false);
            }
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />
            <Container component="main" maxWidth="lg" sx={{ mt: 4, mb: 2 }}>
                <Grid container spacing={4}>
                    {/* Form Section */}
                    <Grid item xs={12} md={8}>
                        <Paper elevation={3} sx={{ p: 4 }}>
                            <Typography variant="h6" gutterBottom>
                                Checkout
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    label="First Name"
                                    name="firstName"
                                    value={form.firstName}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    error={error.firstName}
                                    helperText={error.firstName && "First name is required"}
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    label="Last Name"
                                    name="lastName"
                                    value={form.lastName}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    error={error.lastName}
                                    helperText={error.lastName && "Last name is required"}
                                    sx={{ mb: 2 }}
                                />
                                
                                <TextField
                                    label="Email"
                                    name="email"
                                    value={form.email}
                                    onChange={handleChange}
                                    type="email"
                                    fullWidth
                                    required
                                    error={error.email}
                                    helperText={error.email && "Email is required"}
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    label="Address"
                                    name="address"
                                    value={form.address}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    multiline
                                    rows={3}
                                    error={error.address}
                                    helperText={error.address && "Address is required"}
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    label="GST Number"
                                    name="gst"
                                    value={form.gst}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    error={error.lastName}
                                    sx={{ mb: 2 }}
                                />
                                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                                    Subtotal: ₹{subtotal.toFixed(2)}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                                    GST (18%): ₹{gst.toFixed(2)}
                                </Typography>
                                <Typography variant="h6" sx={{ mt: 1 }}>
                                    Total (including GST): ₹{total.toFixed(2)}
                                </Typography>
                                <Button variant="contained" color="primary" fullWidth sx={{ mt: 3 }} disabled={loading} type="submit">
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )} Confirm and Pay
                                </Button>
                            </form>
                        </Paper>
                    </Grid>

                    {/* Cart Section */}
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Cart Items
                            </Typography>
                            {loading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                cart && cart.length > 0 ? (
                                    cart.map((item) => (
                                        <Box key={item.id} sx={{ mb: 2 }}>
                                            <Typography>
                                                {item.name} ({item.type}) - ₹{item.price}
                                            </Typography>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography>No items in the cart.</Typography>
                                )
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </Box>
    );
};

export default Checkout;