import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Chip,
  Pagination
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import api from '../utils/api';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const BuyerAppointments = () => {
  const { buyerId } = useParams();
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [stats, setStats] = useState({ total: 0, completed: 0 });
  const [totalPages, setTotalPages] = useState(1);

  const fetchAppointments = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`buyers/appointments-admin/${buyerId}/`, {
        params: {
          search: search,
          limit: 10,
          offset: (page - 1) * 10,
        }
      });
      setAppointments(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10));
      setStats({
        total: response.data.total_appointments,
        completed: response.data.completed_appointments
      });
    } catch (err) {
      setError('Failed to fetch appointments. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, [buyerId, search, page]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleMarkCompleted = async (appointmentId) => {
    try {
      await api.post(`buyers/appointment/${appointmentId}/mark-completed/`);
      // Refresh the appointments list
      fetchAppointments(true);
    } catch (err) {
      setError('Failed to mark appointment as completed. Please try again.');
    }
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        Buyer Appointments
      </Typography>
      
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Appointment Stats</Typography>
              <Typography variant="body1">Total Appointments: <Chip label={stats.total} color="primary" /></Typography>
              <Typography variant="body1">Completed Appointments: <Chip label={stats.completed} color="success" /></Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Search Appointments"
            variant="outlined"
            value={search}
            onChange={handleSearchChange}
            fullWidth
            InputProps={{
              startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
            }}
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: 'primary.light' }}>
              <TableCell><Typography variant="subtitle1" fontWeight="bold">Seller</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" fontWeight="bold">Day</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" fontWeight="bold">Start Time</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" fontWeight="bold">Status</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" fontWeight="bold">Actions</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointments.map((appointment) => (
              <TableRow key={appointment.id} hover>
                <TableCell>{appointment.seller.organization_name}</TableCell>
                <TableCell>{appointment.day}</TableCell>
                <TableCell>{new Date(appointment.start_time).toLocaleTimeString()}</TableCell>
                <TableCell>
                  <Chip 
                    label={appointment.is_completed ? 'Completed' : 'Pending'} 
                    color={appointment.is_completed ? 'success' : 'warning'}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      startIcon={<CheckCircleOutlineIcon />}
                      onClick={() => handleMarkCompleted(appointment.id)}
                    >
                      Mark Completed
                    </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <CircularProgress />
        </Box>
      )}
      {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
      
      {!loading && appointments.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}
    </Box>
  );
};

export default BuyerAppointments;
