import React from 'react';
import { Modal, Typography, Box } from '@mui/material';

// Modal style
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ErrorModal = ({ error, handleClose }) => {
  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="error-modal-title"
      aria-describedby="error-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="error-modal-title" variant="h6" component="h2" color="error">
          Error
        </Typography>
        <Typography id="error-modal-description" sx={{ mt: 2 }}>
          {error}
        </Typography>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
