import React from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Button,
    Container,
    Grid
} from '@mui/material';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

const PaymentProcessingPage = () => {
    const [searchParams] = useSearchParams();

    const orderId = searchParams.get('orderId') || 'N/A';
    const amount = searchParams.get('amount') || 'N/A';
    const date = searchParams.get('date') || new Date().toLocaleDateString('en-GB');
    const login = searchParams.get('login');
    const navigate = useNavigate();

    const gotoProfile = () => {
        if (login === "seller") {
            navigate('/seller-profile');
        } else if (login === "member") {
            navigate('/member-profile');
        } else {
            navigate('/');
        }
    };

    // In a real application, you would implement a way to check the payment status
    // and navigate to the appropriate page (success or failure) when the status is known

    return (
        <>
            <Header />
            <Container maxWidth="sm">
                <Box sx={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Card elevation={3}>
                        <CardContent sx={{ textAlign: 'center', py: 4 }}>
                            <HourglassEmptyIcon sx={{ fontSize: 60, color: 'info.main', mb: 2 }} />
                            <Typography variant="h4" component="div" gutterBottom color="info.main">
                                Payment Processing
                            </Typography>
                            <Typography variant="body1" color="text.secondary" paragraph>
                                Your payment is being processed. You can safely leave this page.
                            </Typography>
                            <Typography variant="body2" color="text.secondary" paragraph>
                                We will notify you once the transaction is complete.
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Transaction Details:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Order #: {orderId}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Amount: ₹ {amount}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Date: {date}
                            </Typography>
                            <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={gotoProfile}>
                                        Return to Home
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
            <Footer />
        </>
    );
};

export default PaymentProcessingPage;