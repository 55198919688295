import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Button, Alert, CircularProgress, TextField, Paper, Grid } from '@mui/material';
import api from '../utils/api';

const SellerKitScanner = () => {
  const [inputValue, setInputValue] = useState('');
  const [scannedData, setScannedData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleScan = async () => {
    if (inputValue) {
      setLoading(true);
      setError(null);

      try {
        const response = await api.post('buyers/seller-kit/', { qr_code: inputValue });
        setScannedData(response.data);
        setInputValue(''); // Clear input after successful scan
      } catch (err) {
        setError(err.response?.data?.error || 'An error occurred while processing the input');
      } finally {
        setLoading(false);
        inputRef.current.focus(); // Refocus input after scan
      }
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Seller Kit Scanner
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Enter QR Code"
              variant="outlined"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleScan();
                }
              }}
              inputRef={inputRef}
              fullWidth
              autoFocus
            />
          </Box>
          <Button variant="contained" onClick={handleScan} disabled={!inputValue || loading}>
            Process QR Code
          </Button>
          {loading && <CircularProgress sx={{ ml: 2 }} />}
          {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        </Grid>
        <Grid item xs={12} md={6}>
          {scannedData && (
            <Paper elevation={3} sx={{ p: 2, bgcolor: scannedData.created ? 'success.light' : 'error.light', height: '100%' }}>
              <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                {scannedData.created ? 'Kit Issued Successfully' : 'Kit Already Issued'}
              </Typography>
              <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Typography variant="h6" gutterBottom>Scanned Kit Information:</Typography>
                    <Typography><strong>Name:</strong> {scannedData.name}</Typography>
                    <Typography><strong>Organization:</strong> {scannedData.organization_name}</Typography>
                    <Typography sx={{ 
                      bgcolor: 'primary.light', 
                      color: 'primary.contrastText', 
                      p: 1, 
                      borderRadius: 1,
                      display: 'inline-block',
                      mt: 1,
                      mb: 1
                    }}>
                      <strong>Allowance:</strong> {scannedData.number_of_slots}
                    </Typography>
                    <Typography><strong>Issued To:</strong> {scannedData.issued_to}</Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {scannedData.photo && (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <img 
                          src={scannedData.photo} 
                          alt="Seller Photo" 
                          style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }} 
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SellerKitScanner;
