import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const ThankYou = () => {
  const handleClose = () => {
    // Send a message to the mobile app to close the webview
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('close');
    } else {
      console.log('Close webview');
      // Fallback for when not in a webview
      window.close();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 400,
        }}
      >
        <CheckCircleOutlineIcon
          color="success"
          sx={{ fontSize: 60, mb: 2 }}
        />
        <Typography variant="h4" component="h1" gutterBottom>
          Thank You!
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          Your survey has been successfully submitted. We appreciate your feedback!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          sx={{ mt: 2 }}
        >
          Close
        </Button>
      </Paper>
    </Box>
  );
};

export default ThankYou;
